import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import ProductsTable from "./ProductsTableOrigin";
import ShippingTable from "./ShippingTable";
import { getShippingInfo } from "../../../actions/checkoutAction";
import { Spinner } from "../../Helpers/Loaders/Spinner";
import BackorderDialog from "./BackorderDialog";
import CheckoutPage from "./Sections/CheckoutPage";
import { getCart } from "../../../actions/cartAction";

export default function Checkout({ cart = true }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validate user
  const { search } = useLocation(); // search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // Get shipping information (customer information for order)
  const shippingInfo = useSelector((state) => state.getShippingInfo);
  const { loading, error, order: shipInfo } = shippingInfo;

  const orderList = shipInfo?.data.result.orderList;
  const shipToAddressList = shipInfo?.data.result.shipToAddress;
  const billToAddressList = shipInfo?.data.result.billToAddress;
  const userAccountInfo = shipInfo?.data.result.userAccount;

  // Get cart
  const cartItems = useSelector((state) => state.getCart);
  const { loadingItem, errorItem, cartItems: items } = cartItems;
  const [localItems, setLocalItems] = useState([]);

  // Backlog alert
  const [isModalOpen, setModalOpen] = useState(false);
  const [backorderItems, setBackorderItems] = useState([]);

  console.log("localItems in checkout index- ", localItems);

  // Update items in cart
  const updateLocalItems = (updatedItems) => {
    setLocalItems(updatedItems);
  };

  useEffect(() => {
    setLocalItems(items?.data.result);
  }, [items]);

  // Delete item
  const handleItemRemoved = (itemProductId) => {
    setLocalItems((prevState) =>
      prevState.filter((item) => item.pid !== itemProductId)
    );
  };

  // Backlog alert
  const handleGoToCart = () => {
    setModalOpen(false); // Close the dialog
    navigate("/cart"); // Navigate to the cart page
  };

  const handleConfirmOrder = () => {
    setModalOpen(false); // Close the dialog
  };

  // Check backorder and Calculate totalNumberOfItems
  const totalNumberOfItems = useMemo(() => {
    const items = orderList?.filter(
      (item) => item.availableQty < item.qty || item.availableQty === 0
    );
    if (items?.length > 0) {
      setBackorderItems(items);
      setModalOpen(true);
    } else {
      // Proceed with order placement
    }

    return orderList?.reduce((total, item) => total + item.qty, 0) || 0;
  }, [orderList]);

  // User validation and Call API
  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logined ");
    }
    dispatch(getShippingInfo());
    dispatch(getCart(userId));
  }, [dispatch, navigate, redirect, userInfo]);

  const defaultCarrierService = shipInfo?.data.result.defaultCarrierService;

  // Spinner while checkout loading
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const toggleCheckoutLoading = (isLoading) => {
    setLoadingCheckout(isLoading);
  };

  return (
    <Layout childrenClasses={`${cart ? "pt-0 pb-0" : ""} relative`}>
      {/* This line will render the spinner when loadingCheckout is true */}
      {loadingCheckout && <Spinner />}

      <BackorderDialog
        dialogOpen={isModalOpen} // Previously isOpen
        setDialogOpen={setModalOpen} // Previously onClose
        backorderItems={backorderItems}
        onConfirm={handleConfirmOrder}
        handleGoToCart={handleGoToCart}
        handleConfirmOrder={handleConfirmOrder}
      />

      <div className="cart-page-wrapper w-full pb-60px white-background">
        <div className="w-full mb-[30px]">
          {/* Page history */}
          <PageTitle
            title="Checkout"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Cart", path: "/cart" },
              { name: "Checkout", path: "/shippingInfo" },
            ]}
          />
        </div>
        {/* product list */}
        <div className="w-full mt-[23px]">
          <div className="container-x mx-auto">
            {/* <ProductsTable cartItems={items?.data.result} /> */}
            <CheckoutPage
              isLoading={loading}
              userAccountInfo={userAccountInfo}
              // cartItems={orderList}
              cartItems={localItems}
              onItemRemoved={handleItemRemoved}
              updateLocalItems={updateLocalItems}
              shipToAddresses={shipToAddressList}
              deliveryOption={defaultCarrierService}
              billToAddresses={billToAddressList}
              //totalNumberOfItems={totalNumberOfItems}
              toggleCheckoutLoading={toggleCheckoutLoading}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
