import React from "react";
import { Link } from "react-router-dom";


export default function BreadcrumbCom({ paths = [] }) {
  return (
    <>
      {paths && paths.length > 0 && (
        <div className="breadcrumb-wrapper font-400 text-[13px] text-qblack  mt-[15px] mb-[15px]">
          {paths.map((path, index) => (
            <span key={path.name}>
               <Link to={path.path}>
                <span className="mx-1 capitalize">{path.name}</span>
                </Link>
              {index < paths.length - 1 && 
              <span className="sperator">/</span>}
            </span>
          ))}
        </div>
      )}
    </>
  );
}
