import Axios from "axios";

import {
  PUT_PASSWORDCHANGE_FAIL,
  PUT_PASSWORDCHANGE_REQUEST,
  PUT_PASSWORDCHANGE_SUCCESS,
  GET_MYACCOUNT_FAIL,
  GET_MYACCOUNT_REQUEST,
  GET_MYACCOUNT_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  TOKEN_REQUEST,
  TOKEN_REQUEST2,
} from "../constants/userAuthConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const changePasswordAction = (resetPassword) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let userId = null;
  if (token) {
    userId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: PUT_PASSWORDCHANGE_REQUEST, payload: { resetPassword } });

  let { data } = "";
  try {
    data = await Axios.put(`${apiUrl}/userAuth/changePassword`, resetPassword, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: PUT_PASSWORDCHANGE_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: PUT_PASSWORDCHANGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: PUT_PASSWORDCHANGE_SUCCESS, payload: data });
  console.log("changePasswordAction in action : ", data.data);
  return data;
};

export const getMyAccount = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_MYACCOUNT_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/myAccount/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_MYACCOUNT_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_MYACCOUNT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_MYACCOUNT_SUCCESS, payload: data.data });
  console.log("myAccount in action : ", data.data);
};

export const signin = (username, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { username, password } });

  try {
    const { data } = await Axios.post(`${apiUrl}/userAuth/login`, {
      username,
      password,
    });

    console.log(data.result);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
    localStorage.setItem("userId", JSON.stringify(data.result.user.userId));
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload:
        // error.response && error.response.data.message
        //   ? error.response.data.message
        //   : error.message,

        error && error.response ? error.response : error,
    });
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_SIGNOUT });
};

// when user accesses sign in. If the token isn't empty, the signin status is maintained.
export const tokencheck = () => async (dispatch) => {
  try {
    dispatch({ type: TOKEN_REQUEST });
    let { data } = await Axios.get(`${apiUrl}/userAuth/Users/tokencheck/`);
    data = 1;
    return data;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

// when user access website, if token has expired, the website moves sign-in page or user just can access each view
export const tokencheck2 = () => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  if (!token) {
    console.log("no token", token);
    return;
  }

  try {
    dispatch({ type: TOKEN_REQUEST2 });
    const { data } = await Axios.get(`${apiUrl}/userAuth/Users/tokencheck2/`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
     return data;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};
