import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import AllProductPage from "./components/AllProductPage";
import ProductListPage from "./components/ProductListPage";
/* Promotion */
import PromoMatrixJacketDec2024 from "./components/Promotions/EmailBlast/MatrixJacket_Dec2024";
import PromoMomentumJacketDec2024 from "./components/Promotions/EmailBlast/MomentumJacket_Dec2024";
import PromoRedPandaJacketDec2024 from "./components/Promotions/EmailBlast/RedPandaJacket_Dec2024";
import PromoMOMENTUMAug2024 from "./components/Promotions/EmailBlast/MOMENTUM_Aug2024";
import PromoMATRIXJul2024 from "./components/Promotions/EmailBlast/MATRIX_Jul2024";
import PromoEONJul2024 from "./components/Promotions/EmailBlast/EON_Jul2024";
import PromoEONMATRIXDec2024 from "./components/Promotions/EmailBlast/EON_MATRIX_Dec2024";
import PromoReadyGoJul2024 from "./components/Promotions/EmailBlast/ReadyGo_Jul2024";
import PromoNewSpringColors01 from "./components/Promotions/MainBanner/New_Spring_Colors_01";
import PromoSpringPrints02 from "./components/Promotions/MainBanner/Spring_Prints_02";
import PromoSpringPrints03 from "./components/Promotions/MainBanner/Spring_Prints_03";
import PromoEasterColors from "./components/Promotions/MainBanner/Easter_Colors";
import PromoEON from "./components/Promotions/SubBanner/EON";
import PromoMomentumMensFocus from "./components/Promotions/EmailBlast/Momentum_Mens_Focus_Sep2024";
import PromoJacket from "./components/Promotions/EmailBlast/Jackets_Nov2024";
import PromoBlackFridayReadyGo from "./components/Promotions/EmailBlast/BlackFriday_ReadyGo_Nov2024";
import PromoBlackFriday2024 from "./components/Promotions/MainBanner/BlackFriday_2024_1";
import PromoMomentumDec2024 from "./components/Promotions/EmailBlast/Momentum_Dec2024";
/* Collection */
import Bestee from "./components/Collections/Bestee";
import BlazeJacket from "./components/Collections/BlazeJacket";
import Blossom from "./components/Collections/Blossom";
import BlossomSignature from "./components/Collections/BlossomSignature";
import Core from "./components/Collections/Core";
import EON from "./components/Collections/EON";
import EONSport from "./components/Collections/EONSport";
import Knits from "./components/Collections/Knits";
import LabCoats from "./components/Collections/LabCoats";
import MatrixUnderscrub from "./components/Collections/MatrixUnderscrub";
import Matrix from "./components/Collections/Matrix";
import MatrixBasic from "./components/Collections/MatrixBasic";
import MatrixImpulse from "./components/Collections/MatrixImpulse";
import MatrixMens from "./components/Collections/MatrixMens";
import MatrixPro from "./components/Collections/MatrixPro";
import MatrixProMens from "./components/Collections/MatrixProMens";
import Momentum from "./components/Collections/Momentum";
import MomentumMens from "./components/Collections/MomentumMens";
import MomentumLabCoats from "./components/Collections/MomentumLabCoats";
import PrimaFlex from "./components/Collections/PrimaFlex";
import Focus from "./components/Collections/Focus";
import Prints from "./components/Collections/Prints";
import PureSoft from "./components/Collections/PureSoft";
import ReadyGO from "./components/Collections/ReadyGO";
import RedPanda from "./components/Collections/RedPanda";
import Smart from "./components/Collections/Smart";
import PPEProducts from "./components/Accessories/PPEProducts";
import ReadyGO2 from "./components/Accessories/ReadyGO";
import ScrubCap from "./components/Accessories/ScrubCap";
import Mask from "./components/Accessories/Mask";
import ReadyGoBackpack from "./components/Bags/ReadyGo_Backpack";
import ReadyGoClutch from "./components/Bags/ReadyGo_Clutch";
import ReadyGoSatchel from "./components/Bags/ReadyGo_Satchel";
import ReadyGoStethoscopeCase from "./components/Bags/ReadyGo_Stethoscope_Case";

/* IRG */
import IRGAll from "./components/Collections/IRG/IRGAll";
import IRGEdge from "./components/Collections/IRG/IRGEdge";
import IRGElevate from "./components/Collections/IRG/IRGElevate";
import IRGElite from "./components/Collections/IRG/IRGElite";
import IRGEPIC from "./components/Collections/IRG/IRGEPIC";
import IRGMarketingMaterials from "./components/Collections/IRG/IRGMarketingMaterials";

/* Users */
import Login from "./components/Auth/Login/index";
import CustomerUsers from "./components/SubAccount/CustomerUsers";
import CustomerRoles from "./components/SubAccount/CustomerRoles";
import AddUser from "./components/SubAccount/AddUser";
import AddRole from "./components/SubAccount/AddRole";
import EditUser from "./components/SubAccount/EditUser";
import EditRole from "./components/SubAccount/EditRole";
import PasswordRequest from "./components/Auth/PasswordReset/PasswordRequest";

import Signup from "./components/Auth/Signup";
import BecomeSaller from "./components/BecomeSaller";
import Blogs from "./components/Blogs";
import Blog from "./components/Blogs/Blog.jsx";

import Contact from "./components/Contact";
import Faq from "./components/Faq";
import FlashSale from "./components/FlashSale";
import FourZeroFour from "./components/FourZeroFour";
import Home from "./components/Home";
import HomeTwo from "./components/HomeTwo";
import Careers from "./components/Careers";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ReturnPolicy from "./components/ReturnPolicy";
import ProductsCompaire from "./components/ProductsCompaire/index";
import SallerPage from "./components/SallerPage";
import Sallers from "./components/Sellers";
import SingleProductPage from "./components/SingleProductPage";
import ProductDetailPage from "./components/ProductDetailPage";
import IRGProductDetailPage from "./components/IRGProductDetailPage";
import TermsConditions from "./components/TermsConditions/index";
import TrackingOrder from "./components/TrackingOrder";
/* Cart */
import Wishlist from "./components/Wishlist";
import CartPage from "./components/CartPage";
import Quickorder from "./components/Quickorder";
import Checkout from "./components/OrderProcess/Checkout/index";
import CheakoutPage from "./components/CheakoutPage";
/* Order */
import OrderConfirm from "./components/OrderProcess/OrderConfirm";
import Orders from "./components/OrderProcess/Orders";
import OrderDetails from "./components/OrderProcess/OrderDetails";
/* My account */
import Dashboard from "./components/Dashboard";
import Profile from "./components/Auth/Profile";
import EmailRequest from "./components/Auth/PasswordReset/EmailRequest";
/* Accounting */
import ViewStatementTab from "./components/Dashboard/tabs/ViewStatementTab";
import PaymentConfirmTab from "./components/Dashboard/tabs/PaymentConfirmTab";

import ImportOrderFile from "./components/BulkOrder/ImportOrderFile";
import BulkOrder from "./components/BulkOrder";
import BulkOrderConfirm from "./components/BulkOrder/OrderConfirm/index";

export default function Routers() {
  return (
    <Routes>
      {/* Home */}
      <Route exact path="/" element={<Home />} />
      <Route exact path="/home-two" element={<HomeTwo />} />
      {/* Login */}
      <Route exact path="/Login" element={<Login />} />
      {/* Password reset */}
      <Route
        exact
        path="/password-reset-emailrequest"
        element={<EmailRequest />}
      />
      <Route
        exact
        path="/password-reset-passwordrequest/:UserId/:Token"
        element={<PasswordRequest />}
      />
      {/* Promotions -EmailBlast */}
      <Route
        exact
        path="/Promotions/EmailBlast/MatrixJacket_Dec2024"
        element={< PromoMatrixJacketDec2024 />}
      />
      <Route
        exact
        path="/Promotions/EmailBlast/MomentumJacket_Dec2024"
        element={< PromoMomentumJacketDec2024 />}
      />
        <Route
        exact
        path="/Promotions/EmailBlast/RedPandaJacket_Dec2024"
        element={< PromoRedPandaJacketDec2024 />}
      />
      <Route
        exact
        path="/Promotions/EmailBlast/MOMENTUM_Aug2024"
        element={<PromoMOMENTUMAug2024 />}
      />
      <Route
        exact
        path="/Promotions/EmailBlast/MATRIX_Jul2024"
        element={<PromoMATRIXJul2024 />}
      />
      <Route
        exact
        path="/Promotions/EmailBlast/EON_Jul2024"
        element={<PromoEONJul2024 />}
      />
      <Route
        exact
        path="/Promotions/EmailBlast/EON_MATRIX_Dec2024"
        element={<PromoEONMATRIXDec2024 />}
      />
      <Route
        exact
        path="/Promotions/EmailBlast/ReadyGo_Jul2024"
        element={<PromoReadyGoJul2024 />}
      />
      <Route
        exact
        path="/Promotions/EmailBlast/Momentum_Dec2024"
        element={<PromoMomentumDec2024 />}
      />

      {/* Promotions -MainBanner */}
      <Route
        exact
        path="/Promotions/MainBanner/New_Spring_Colors_01"
        element={<PromoNewSpringColors01 />}
      />
      <Route
        exact
        path="/Promotions/MainBanner/Spring_Prints_02"
        element={<PromoSpringPrints02 />}
      />
      <Route
        exact
        path="/Promotions/MainBanner/Spring_Prints_03"
        element={<PromoSpringPrints03 />}
      />
      <Route
        exact
        path="/Promotions/MainBanner/Easter_Colors"
        element={<PromoEasterColors />}
      />

      <Route
        exact
        path="/Promotions/MainBanner/BlackFriday_2024_1"
        element={<PromoBlackFriday2024 />}
      />
      <Route
        exact
        path="/Promotions/Momentum_mens_focus"
        element={<PromoMomentumMensFocus />}
      />
      <Route
        exact
        path="/Promotions/Jackets_Nov2024"
        element={<PromoJacket />}
      />
      <Route
        exact
        path="/Promotions/BlackFriday_ReadyGo_Nov2024"
        element={<PromoBlackFridayReadyGo />}
      />
     
      {/* Promotions -MainBanner */}
      <Route exact path="/Promotions/SubBanner/EON" element={<PromoEON />} />

      {/* Collection */}
      <Route exact path="/all-products" element={<AllProductPage />} />
      <Route exact path="/Collection/Bestee" element={<Bestee />} />
      <Route exact path="/Collection/BlazeJacket" element={<BlazeJacket />} />
      <Route exact path="/Collection/Blossom" element={<Blossom />} />
      <Route
        exact
        path="/Collection/BlossomSignature"
        element={<BlossomSignature />}
      />
      <Route exact path="/Collection/Core" element={<Core />} />
      <Route exact path="/Collection/EON" element={<EON />} />
      <Route exact path="/Collection/EONSport" element={<EONSport />} />
      <Route exact path="/Collection/Knits" element={<Knits />} />
      <Route exact path="/Collection/LabCoat" element={<LabCoats />} />
      <Route
        exact
        path="/Collection/MatrixUnderscrub"
        element={<MatrixUnderscrub />}
      />
      <Route exact path="/Collection/Matrix" element={<Matrix />} />
      <Route exact path="/Collection/MatrixBasic" element={<MatrixBasic />} />
      <Route
        exact
        path="/Collection/MatrixImpulse"
        element={<MatrixImpulse />}
      />
      <Route exact path="/Collection/MatrixMens" element={<MatrixMens />} />
      <Route exact path="/Collection/MatrixPro" element={<MatrixPro />} />
      <Route
        exact
        path="/Collection/MatrixProMens"
        element={<MatrixProMens />}
      />
      <Route exact path="/Collection/Momentum" element={<Momentum />} />
      <Route exact path="/Collection/MomentumMens" element={<MomentumMens />} />
      <Route
        exact
        path="/Collection/MomentumLabCoats"
        element={<MomentumLabCoats />}
      />
      <Route exact path="/Collection/PrimaFlex" element={<PrimaFlex />} />
      <Route exact path="/Collection/Focus" element={<Focus />} />
      <Route exact path="/Collection/Prints" element={<Prints />} />
      <Route exact path="/Collection/PureSoft" element={<PureSoft />} />
      <Route exact path="/Collection/ReadyGO" element={<ReadyGO />} />
      <Route exact path="/Collection/RedPanda" element={<RedPanda />} />
      <Route exact path="/Collection/Smart" element={<Smart />} />

      {/* IRG */}
      <Route exact path="/IRG/All" element={<IRGAll />} />
      <Route exact path="/IRG/Edge" element={<IRGEdge />} />
      <Route exact path="/IRG/Elevate" element={<IRGElevate />} />
      <Route exact path="/IRG/Elite" element={<IRGElite />} />
      <Route exact path="/IRG/EPIC" element={<IRGEPIC />} />
      <Route
        exact
        path="/IRG/MarketingMaterials"
        element={<IRGMarketingMaterials />}
      />

      {/* Accessories */}
      <Route exact path="/Accessory/PPEProducts" element={<PPEProducts />} />
      <Route exact path="/Accessory/ReadyGo" element={<ReadyGO2 />} />
      <Route exact path="/Accessory/ScrubCap" element={<ScrubCap />} />
      <Route exact path="/Accessory/Mask" element={<Mask />} />
      {/* Bags */}
      <Route
        exact
        path="/Bags/ReadyGo_Backpack"
        element={<ReadyGoBackpack />}
      />
      <Route exact path="/Bags/ReadyGo_Clutch" element={<ReadyGoClutch />} />
      <Route exact path="/Bags/ReadyGo_Satchel" element={<ReadyGoSatchel />} />
      <Route
        exact
        path="/Bags/ReadyGo_Stethoscope_Case"
        element={<ReadyGoStethoscopeCase />}
      />

      {/* Product list as search result */}
      <Route exact path="/AllItems/:SearchTerm" element={<ProductListPage />} />
      {/*<Route exact path="/AllItems" element={<ProductListPage />} /> */}
      {/* Dropdown Headers */}
      {/* Product detail page */}
      <Route exact path="/single-product" element={<SingleProductPage />} />
      <Route
        path="/:Collection/:StyleNumber/:ColorCode/:Fit"
        element={<ProductDetailPage />}
      />
      <Route
        path="/IRG/:Collection/:StyleNumber/:ColorCode/:Fit"
        element={<IRGProductDetailPage />}
      />
      {/* Role */}
      <Route exact path="/customerroles" element={<CustomerRoles />} />
      <Route exact path="/customerusers" element={<CustomerUsers />} />
      <Route exact path="/adduser" element={<AddUser />} />
      <Route exact path="/addrole" element={<AddRole />} />
      <Route exact path="/edituser" element={<EditUser />} />
      <Route exact path="/editrole" element={<EditRole />} />
      {/* Checkout */}
      <Route exact path="/cart" element={<CartPage />} />
      <Route exact path="/quickorder" element={<Quickorder />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/orderconfirm/:CustomerPO" element={<OrderConfirm />} />
      {/* Orders */}
      <Route path="/orders" element={<Orders />} />
      <Route path="/orderdetails/:OrderId" element={<OrderDetails />} />
      {/* Payment */}
      <Route path="/paymentconfirm" element={<PaymentConfirmTab />} />

      {/* Import Order */}
      <Route exact path="/Bulkorder" element={<BulkOrder />} />
      <Route exact path="/Importorderfile" element={<ImportOrderFile />} />
      <Route
        path="/Importorderfile/Confirmation"
        element={<BulkOrderConfirm />}
      />

      {/* Statement */}
      <Route
        path="/viewStatement/:requestedNo"
        component={<ViewStatementTab />}
      />
      <Route exact path="/checkout2" element={<CheakoutPage />} />
      <Route exact path="/wishlist" element={<Wishlist />} />
      <Route exact path="/flash-sale" element={<FlashSale />} />
      <Route exact path="/saller-page" element={<SallerPage />} />
      <Route exact path="/products-compaire" element={<ProductsCompaire />} />
      <Route exact path="/sallers" element={<Sallers />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route exact path="/blogs/blog" element={<Blog />} />
      <Route exact path="/tracking-order" element={<TrackingOrder />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/faq" element={<Faq />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/profile" element={<Profile />} />
      <Route exact path="/careers" element={<Careers />} />
      <Route exact path="/myaccount" element={<Dashboard />} />
      <Route exact path="/become-saller" element={<BecomeSaller />} />
      <Route exact path="/return-policy" element={<ReturnPolicy />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-conditions" element={<TermsConditions />} />
      <Route exact path="*" element={<FourZeroFour />} />
    </Routes>
  );
}
