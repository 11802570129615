import Axios from "axios";

import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PROMOTION_ITEMS_FAIL,
  PROMOTION_ITEMS_REQUEST,
  PROMOTION_ITEMS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
} from "../constants/productConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export

{
  /*export const listProducts =
  ({
    programId,
    GarmentType = "",
    color = "",
    fit = "",
    size = "",
    inseam = "",
    priceFrom = "",
    priceTo = "",
  }) =>
  async (dispatch) => {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });
    try {
      let { data } = "";

      console.log(GarmentType);
      console.log(fit);
      console.log(size);
      console.log(inseam);
      console.log(color);
      console.log(priceFrom, priceTo);

      if (
        GarmentType ||
        color ||
        fit ||
        size ||
        inseam ||
        priceFrom ||
        priceTo
      ) {
        //if there is no token, go to login page
        const token = localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : null;

        if (!token) {
          //redirect to login page and return
          console.log("no token", token);

          return;
        }
        //if there is token, verificate the token
        try {
          data = await Axios.get(
            `/products/${programId}?garmentType=${GarmentType}&color=${color}&fit=${fit}&size=${size}&inseam=${inseam}&priceFrom=${priceFrom}&priceTo=${priceTo}`,
            { headers: { Authorization: `Bearer ${token.result.token}` } }
          );
        } catch (error) {
          // dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
          // return;
          if (error.response && error.response.status === 401) {
            dispatch({
              type: PRODUCT_LIST_FAIL,
              payload: error.response.data.error,
            });
          } else {
            dispatch({
              type: PRODUCT_LIST_FAIL,
              payload:
                error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
            });
          }
          return;
        }
      } else {
        data = await Axios.get(`${apiUrl}/products/${programId}`);
      }
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
*/
}

export const listProducts =
  ({
    programId,
    GarmentType = "",
    color = "",
    fit = "",
    size = "",
    inseam = "",
    priceFrom = "",
    priceTo = "",
    searchTerm = "",
  }) =>
  async (dispatch) => {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });
    try {
      let { data } = "";

      console.log(GarmentType);
      console.log(fit);
      console.log(size);
      console.log(inseam);
      console.log(color);
      console.log(priceFrom, priceTo);
      console.log(searchTerm);

      if (
        GarmentType ||
        color ||
        fit ||
        size ||
        inseam ||
        priceFrom ||
        priceTo ||
        searchTerm
      ) {
        //if there is no token, go to login page
        const token = localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : null;

        if (!token) {
          //redirect to login page and return
          console.log("no token", token);

          return;
        }
        //if there is token, verificate the token
        try {
          data = await Axios.get(
            `${apiUrl}/products/getProductList?programId=${programId}&garmentType=${GarmentType}&color=${color}&fit=${fit}&size=${size}&inseam=${inseam}&priceFrom=${priceFrom}&priceTo=${priceTo}&searchTerm=${searchTerm}`,
            { headers: { Authorization: `Bearer ${token.result.token}` } }
          );
        } catch (error) {
          // dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
          // return;
          if (error.response && error.response.status === 401) {
            dispatch({
              type: PRODUCT_LIST_FAIL,
              payload: error.response.data.error,
            });
          } else {
            dispatch({
              type: PRODUCT_LIST_FAIL,
              payload:
                error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
            });
          }
          return;
        }
      } else {
        data = await Axios.get(`${apiUrl}/products/getProductList?`);
      }

      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });

      console.log("data : ", data);
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

{
  /* 
export const getPromotionItems = (styleNumbers) => async (dispatch) => {
  // get token
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  if (!token) {
    // redirect to login page and return
    console.log("no token", token);
    return;
  }

  const queryString = styleNumbers.map((sn) => `styleNumbers=${sn}`).join("&");
  const url = `${apiUrl}/products/getPromotionItems?${queryString}`;

  try {
    dispatch({
      type: PROMOTION_ITEMS_REQUEST,
    });

    const data = await Axios.get(url, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });

    dispatch({
      type: PROMOTION_ITEMS_SUCCESS,
      payload: data, // Set data received from API responses to payload
    });
    console.log(data);
  } catch (error) {
    dispatch({
      type: PROMOTION_ITEMS_FAIL,
      payload: error.message, // Set error message received from API response to payload
    });
    console.error("There was an error!", error);
  }
};
*/
}

export const getPromotionItems =
  ({
    styleNumbers,
    gender = "",
    garmentType = "",
    color = "",
    fit = "",
    size = "",
    inseam = "",
    priceFrom = "",
    priceTo = "",
    searchTerm = "",
  }) =>
  async (dispatch) => {
    // Token retrieval
    const token = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    if (!token) {
      console.error("No token found. Redirecting to login.");
      return;
    }

    // Construct query string
    const queryParams = new URLSearchParams();
 

    if (styleNumbers && styleNumbers.length > 0) {
      styleNumbers.forEach((sn) => queryParams.append("styleNumbers", sn));
    }
    if (gender) queryParams.append("gender", gender); 
    if (garmentType) queryParams.append("garmentType", garmentType);
    if (color) queryParams.append("color", color);
    if (fit) queryParams.append("fit", fit);
    if (size) queryParams.append("size", size);
    if (inseam) queryParams.append("inseam", inseam);
    if (priceFrom) queryParams.append("priceFrom", priceFrom);
    if (priceTo) queryParams.append("priceTo", priceTo);
    if (searchTerm) queryParams.append("searchTerm", searchTerm);

    const url = `${apiUrl}/products/getPromotionItems?${queryParams.toString()}`;

 
 
    try {
      dispatch({
        type: PROMOTION_ITEMS_REQUEST,
      });

      // API call
      const { data } = await Axios.get(url, {
        headers: { Authorization: `Bearer ${token.result.token}` },
      });

      dispatch({
        type: PROMOTION_ITEMS_SUCCESS,
        payload: data.result, // Set data received from API to payload
      });
   
    } catch (error) {
      dispatch({
        type: PROMOTION_ITEMS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      console.error("Error fetching promotion items: ", error);
    }
  };

export const detailProduct =
  ({ stylenumber, colorcode, fit }) =>
  async (dispatch) => {
    //request product detail data
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    try {
      let detailData = "";

      //if there is no token, go to login page
      const token = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null;

      if (!token) {
        //redirect to login page and return
        console.log("no token", token);
        return;
      }
      try {
        const response = await Axios.get(
          `${apiUrl}/products/getProductDetail?styleNumber=${stylenumber}&colorCode=${colorcode}&Fit=${fit}`,
          { headers: { Authorization: `Bearer ${token.result.token}` } }
        );
        detailData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response.data.error,
          });
        } else {
          dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          });
        }
        return;
      }

      dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: detailData.result });
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
