import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import ThinBag from "../../../Helpers/icons/ThinBag";
import Middlebar from "./Middlebar";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
import { getCart } from "../../../../actions/cartAction";
import ThinPeople from "../../../Helpers/icons/ThinPeople";
import {
  signout,
  tokencheck,
  tokencheck2,
} from "../../../../actions/userAuthAction";

export default function HeaderOne({ className, drawerAction }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //validate user
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/login"; // check if redirect url exist

  //get user permission
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const shoppingCartPermission =
    userInfo?.result?.user?.permissions.ShoppingCart === 1;

  //get cart
  const cartItems = useSelector((state) => state.getCart);
  const { cartLoading, cartError, cartItems: items } = cartItems;
  const [numberOfItems, setNumberOfItems] = useState(0);

  useEffect(() => {
    const fetchDataAsync = async () => {
      //validate the token in backend side
      const data = await dispatch(tokencheck2());
      if (userInfo) {
        if (data.statusCode !== 200) {
          // if there is token error(if the token is expired), redirect to login page
          dispatch(signout());

          alert("Please sign in to access our website. ");

          // Remove IRG token
          localStorage.removeItem("IRGAccesstoken");
          localStorage.removeItem("IRGtokenExpiry");

          navigate(redirect);
        }
      }
    };

    fetchDataAsync();
  }, []);

  // Dispatch getCart on component mount to get the # of items in shopping cart
  useEffect(() => {
    dispatch(getCart(userInfo?.result?.user?.userId));
  }, [dispatch, userInfo?.result?.user?.userId]);

  // Update the numberOfItems whenever items changes
  useEffect(() => {
    if (items?.data?.result) {
      const totalQty = items.data.result.reduce((total, currentItem) => {
        return total + currentItem.qty;
      }, 0);
      setNumberOfItems(totalQty);
    }
  }, [items]);

  return (
    <header className={` ${className || ""} header-section-wrapper relative`}>
      <TopBar className="quomodo-shop-top-bar" />
      <Middlebar className="quomodo-shop-middle-bar lg:block hidden" />
      <div className="quomodo-shop-drawer lg:hidden block w-full h-[60px] bg-white">
        <div className="w-full h-full flex justify-between items-center px-5">
          <div onClick={drawerAction}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </div>
          <div>
            <a href="/">
              <img
                width="152"
                height="36"
                //src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                src={`https://maevn.s3.us-east-2.amazonaws.com/banners/maevnLogo.PNG`}
                alt="logo"
              />
            </a>
          </div>
          <div className="flex space-x-6 items-center">
            {shoppingCartPermission && (
              <div className="cart relative cursor-pointer">
                <Link to="/cart">
                  <span>
                    <ThinBag />
                  </span>
                </Link>
                <span className="w-[18px] h-[18px] rounded-full bg-qyellow absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px]">
                  {numberOfItems}
                </span>
              </div>
            )}
            <div>
              <button type="button">
                <span>
                  <ThinPeople />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Navbar className="quomodo-shop-nav-bar lg:block hidden" />
    </header>
  );
}
