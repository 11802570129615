import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Checkbox from "../../../Helpers/Checkbox";
import { Scrollbars } from "react-custom-scrollbars";
import { useState } from "react";
import CustomCheckbox from "../../../Helpers/CustomCheckbox";

export default function ProductsFilter({
  filters,
  checkboxHandler,
  volume,
  volumeHandler,
  priceHandler,
  storage,
  filterstorage,
  className,
  filterToggle,
  filterToggleHandler,
}) {
  // State to manage the visibility of the Price Range content
  const [isPriceRangeVisible, setIsPriceRangeVisible] = useState(true);
  const [isLengthVisible, setIsLengthVisible] = useState(true);
  const [isSizeVisible, setIsSizeVisible] = useState(true);
  const [isInseamVisible, setIsInseamVisible] = useState(true);

  // Function to toggle the visibility
  const togglePriceRangeVisibility = () => {
    setIsPriceRangeVisible(!isPriceRangeVisible);
  };

  const toggleLengthVisibility = () => {
    setIsLengthVisible(!isLengthVisible);
  };

  const toggleSizeVisibility = () => {
    setIsSizeVisible(!isSizeVisible);
  };

  const toggleInseamVisibility = () => {
    setIsInseamVisible(!isInseamVisible);
  };

  return (
    <>
      <div
        className={`filter-widget w-full fixed lg:relative left-0 top-0 h-screen z-10 lg:h-auto overflow-y-scroll lg:overflow-y-auto bg-white px-[23px] pt-[30px] ${
          className || ""
        } ${filterToggle ? "block" : "hidden lg:block"}`}
      >
        {/*Category */}
        {/*
        <div className="filter-subject-item pb-10 border-b border-qgray-border">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Category</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Jacket"
                      name="Jacket"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Jacket}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Jacket"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Jacket
                    </label>
                  </div>
                </div>
                <div>
                  <span className="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="4" width="10" height="2" fill="#C4C4C4" />
                      <rect
                        x="6"
                        width="10"
                        height="2"
                        transform="rotate(90 6 0)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </span>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Pants"
                      name="Pants"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Pants}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Pants"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Pants
                    </label>
                  </div>
                </div>
                <div>
                  <span className="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="4" width="10" height="2" fill="#C4C4C4" />
                      <rect
                        x="6"
                        width="10"
                        height="2"
                        transform="rotate(90 6 0)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </span>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Top"
                      name="Top"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Top}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Top"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Top
                    </label>
                  </div>
                </div>
                <div>
                  <span className="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="4" width="10" height="2" fill="#C4C4C4" />
                      <rect
                        x="6"
                        width="10"
                        height="2"
                        transform="rotate(90 6 0)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        */}

        {/* Price Range */}
        <div className="filter-subject-item border-b border-qgray-border ">
          <div className="flex items-center mb-[10px] ">
            <h1
              className="text-black "
              style={{
                fontSize: "16px",
                fontWeight: 700,
                fontStyle: "normal",
                lineHeight: "normal",
                whiteSpace: "nowrap",
              }}
            >
              Price Range
            </h1>
            {/* Spacer to maintain the distance */}
            <div style={{ width: "130px" }}></div>
            {/* Vector Button */}
            <button
              onClick={togglePriceRangeVisibility}
              type="button"
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              {isPriceRangeVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="3"
                  viewBox="0 0 10 3"
                  fill="none"
                >
                  <path d="M0 1.5H10" stroke="black" strokeWidth="1.5" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                >
                  <path d="M0 5.5H10" stroke="black" strokeWidth="1.5" />
                  <path d="M5 0.5L5 10.5" stroke="black" strokeWidth="1.5" />
                </svg>
              )}
            </button>
          </div>

          {/* Conditionally rendered content with consistent bottom margin */}
          <div
            className={`price-range ${
              isPriceRangeVisible ? "mb-[20px]" : "mb-[20px]"
            }`}
          >
            {/* Price Range Content */}
            {isPriceRangeVisible && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "166px",
                    marginBottom: "10px",
                  }}
                >
                  <p
                    style={{
                      color: "#000",
                      font: "Lato",
                      fontSize: "14px",
                      fontWeight: "400",
                      fontStyle: "normal",
                      lineHeight: "normal",
                    }}
                  >
                    ${volume.min}
                  </p>
                  <p
                    style={{
                      color: "#000",
                      font: "Lato",
                      fontSize: "14px",
                      fontWeight: "400",
                      fontStyle: "normal",
                      lineHeight: "normal",
                    }}
                  >
                    ${volume.max}
                  </p>
                </div>
                <InputRange
                  draggableTrack
                  maxValue={100}
                  minValue={0}
                  value={volume}
                  onChange={volumeHandler}
                  onChangeComplete={priceHandler}
                />
              </>
            )}
          </div>
        </div>

        
        {/* Gender */}
        <div className="filter-subject-item border-b border-qgray-border">
          <div className="flex items-center mt-[20px] mb-[15px] ">
            <h1
              className="text-black "
              style={{
                fontSize: "16px",
                fontWeight: 700,
                fontStyle: "normal",
                lineHeight: "normal",
                whiteSpace: "nowrap",
              }}
            >
              Gender
            </h1>
            {/* Spacer to maintain the distance */}
            <div style={{ width: "164px" }}></div>
            {/* Vector Button */}
            <button
              onClick={toggleLengthVisibility}
              type="button"
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              {isLengthVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="3"
                  viewBox="0 0 10 3"
                  fill="none"
                >
                  <path d="M0 1.5H10" stroke="black" strokeWidth="1.5" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                >
                  <path d="M0 5.5H10" stroke="black" strokeWidth="1.5" />
                  <path d="M5 0.5L5 10.5" stroke="black" strokeWidth="1.5" />
                </svg>
              )}
            </button>
          </div>

          {/* Conditionally rendered content with consistent bottom margin */}
          <div
            className={`Length ${isLengthVisible ? "mb-[20px]" : "mb-[20px]"}`}
          >
            {/* Gender */}
            {isLengthVisible && (
              <>
                <div className="filter-items">
                  <ul>
                    <li className="item flex justify-between items-center mb-[10px]">
                      <div className="flex space-x-[8px] items-center">
                        <div>
                          <CustomCheckbox
                            id="Women"
                            name="Women"
                            handleChange={(e) => checkboxHandler(e)}
                            checked={filters.Women}
                            className="hidden-checkbox" // Hide the default checkbox
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="Women"
                            className="length-label capitalize"
                          >
                            Women
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="item flex justify-between items-center mb-[10px]">
                      <div className="flex space-x-[8px] items-center">
                        <div>
                          <CustomCheckbox
                            id="Men"
                            name="Men"
                            handleChange={(e) => checkboxHandler(e)}
                            checked={filters.Men}
                            className="hidden-checkbox"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="Men"
                            className="length-label capitalize"
                          >
                            Men
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="item flex justify-between items-center mb-[10px]">
                      <div className="flex space-x-[8px] items-center">
                        <div>
                          <CustomCheckbox
                            id="Unisex"
                            name="Unisex"
                            handleChange={(e) => checkboxHandler(e)}
                            checked={filters.Unisex}
                            className="hidden-checkbox"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="Unisex"
                            className="length-label capitalize"
                          >
                            Unisex
                          </label>
                        </div>
                      </div>
                    </li> 
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
        

        {/* Length */}
        <div className="filter-subject-item border-b border-qgray-border">
          <div className="flex items-center mt-[20px] mb-[15px] ">
            <h1
              className="text-black "
              style={{
                fontSize: "16px",
                fontWeight: 700,
                fontStyle: "normal",
                lineHeight: "normal",
                whiteSpace: "nowrap",
              }}
            >
              Length
            </h1>
            {/* Spacer to maintain the distance */}
            <div style={{ width: "164px" }}></div>
            {/* Vector Button */}
            <button
              onClick={toggleLengthVisibility}
              type="button"
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              {isLengthVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="3"
                  viewBox="0 0 10 3"
                  fill="none"
                >
                  <path d="M0 1.5H10" stroke="black" strokeWidth="1.5" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                >
                  <path d="M0 5.5H10" stroke="black" strokeWidth="1.5" />
                  <path d="M5 0.5L5 10.5" stroke="black" strokeWidth="1.5" />
                </svg>
              )}
            </button>
          </div>

          {/* Conditionally rendered content with consistent bottom margin */}
          <div
            className={`Length ${isLengthVisible ? "mb-[20px]" : "mb-[20px]"}`}
          >
            {/* Length Content */}
            {isLengthVisible && (
              <>
                <div className="filter-items">
                  <ul>
                    <li className="item flex justify-between items-center mb-[10px]">
                      <div className="flex space-x-[8px] items-center">
                        <div>
                          <CustomCheckbox
                            id="Regular"
                            name="Regular"
                            handleChange={(e) => checkboxHandler(e)}
                            checked={filters.Regular}
                            className="hidden-checkbox" // Hide the default checkbox
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="Regular"
                            className="length-label capitalize"
                          >
                            Regular
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="item flex justify-between items-center mb-[10px]">
                      <div className="flex space-x-[8px] items-center">
                        <div>
                          <CustomCheckbox
                            id="Petite"
                            name="Petite"
                            handleChange={(e) => checkboxHandler(e)}
                            checked={filters.Petite}
                            className="hidden-checkbox"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="Petite"
                            className="length-label capitalize"
                          >
                            Petite
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="item flex justify-between items-center mb-[10px]">
                      <div className="flex space-x-[8px] items-center">
                        <div>
                          <CustomCheckbox
                            id="Short"
                            name="Short"
                            handleChange={(e) => checkboxHandler(e)}
                            checked={filters.Short}
                            className="hidden-checkbox"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="Short"
                            className="length-label capitalize"
                          >
                            Short
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="item flex justify-between items-center mb-[10px]">
                      <div className="flex space-x-[8px] items-center">
                        <div>
                          <CustomCheckbox
                            id="Tall"
                            name="Tall"
                            handleChange={(e) => checkboxHandler(e)}
                            checked={filters.Tall}
                            className="hidden-checkbox"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="Tall"
                            className="length-label capitalize"
                          >
                            Tall
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Size */}
        <div className="filter-subject-item border-b border-qgray-border">
          <div className="flex items-center mt-[20px] mb-[15px] ">
            <h1
              className="text-black "
              style={{
                fontSize: "16px",
                fontWeight: 700,
                fontStyle: "normal",
                lineHeight: "normal",
                whiteSpace: "nowrap",
              }}
            >
              Size
            </h1>
            {/* Spacer to maintain the distance */}
            <div style={{ width: "185px" }}></div>
            {/* Vector Button */}
            <button
              onClick={toggleSizeVisibility}
              type="button"
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              {isSizeVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="3"
                  viewBox="0 0 10 3"
                  fill="none"
                >
                  <path d="M0 1.5H10" stroke="black" strokeWidth="1.5" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                >
                  <path d="M0 5.5H10" stroke="black" strokeWidth="1.5" />
                  <path d="M5 0.5L5 10.5" stroke="black" strokeWidth="1.5" />
                </svg>
              )}
            </button>
          </div>

          {/* Conditionally rendered content with consistent bottom margin */}
          <div className={`Size ${isSizeVisible ? "mb-[20px]" : "mb-[20px]"}`}>
            {isSizeVisible && (
              <>
                {/* Regular sizes */}
                <div>
                  <h1
                    className="text-black mb-[10px]"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Regular
                  </h1>
                  <div
                    className="filter-items size-dotted-border"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      className="flex flex-wrap mb-[10px]"
                      style={{ gap: "10px" }}
                    >
                      {/* Regular - XXS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XXS", checked: !filters.XXS },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XXS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XXS ? "black" : "",
                          color: filters.XXS ? "white" : "",
                        }}
                      >
                        XXS
                      </span>
                      {/* Regular - XS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XS", checked: !filters.XS },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XS ? "black" : "",
                          color: filters.XS ? "white" : "",
                        }}
                      >
                        XS
                      </span>
                      {/* Regular - SM */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "SM", checked: !filters.SM },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.SM ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.SM ? "black" : "",
                          color: filters.SM ? "white" : "",
                        }}
                      >
                        SM
                      </span>

                      {/* Regular - MD */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "MD", checked: !filters.MD },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.MD ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.MD ? "black" : "",
                          color: filters.MD ? "white" : "",
                        }}
                      >
                        MD
                      </span>
                      {/* Regular - LG */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "LG", checked: !filters.LG },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.LG ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.LG ? "black" : "",
                          color: filters.LG ? "white" : "",
                        }}
                      >
                        LG
                      </span>
                      {/* Regular - XL */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XL", checked: !filters.XL },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XL ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XL ? "black" : "",
                          color: filters.XL ? "white" : "",
                        }}
                      >
                        XL
                      </span>
                      {/* Regular - 2XL */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "twoXL", checked: !filters.twoXL },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.twoXL ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.twoXL ? "black" : "",
                          color: filters.twoXL ? "white" : "",
                        }}
                      >
                        2XL
                      </span>
                      {/* Regular - 3XL */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "threeXL",
                              checked: !filters.threeXL,
                            },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.threeXL ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.threeXL ? "black" : "",
                          color: filters.threeXL ? "white" : "",
                        }}
                      >
                        3XL
                      </span>
                      {/* Regular - 4XL */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "fourXL",
                              checked: !filters.fourXL,
                            },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.fourXL ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.fourXL ? "black" : "",
                          color: filters.fourXL ? "white" : "",
                        }}
                      >
                        4XL
                      </span>
                      {/* Regular - 5XL */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "fiveXL",
                              checked: !filters.fiveXL,
                            },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.fiveXL ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.fiveXL ? "black" : "",
                          color: filters.fiveXL ? "white" : "",
                        }}
                      >
                        5XL
                      </span>
                    </div>
                  </div>
                </div>
                {/* Petite sizes */}
                <div>
                  <h1
                    className="text-black mt-[15px] mb-[10px] "
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Petite
                  </h1>
                  <div
                    className="filter-items size-dotted-border"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      className="flex flex-wrap mb-[10px]"
                      style={{ gap: "10px" }}
                    >
                      {/* Petite - XSP */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XSP", checked: !filters.XSP },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XSP ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XSP ? "black" : "",
                          color: filters.XSP ? "white" : "",
                        }}
                      >
                        XSP
                      </span>
                      {/* Petite - SMP */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "SMP", checked: !filters.SMP },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.SMP ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.SMP ? "black" : "",
                          color: filters.SMP ? "white" : "",
                        }}
                      >
                        SMP
                      </span>
                      {/* Petite - MDP */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "MDP", checked: !filters.MDP },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.MDP ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.MDP ? "black" : "",
                          color: filters.MDP ? "white" : "",
                        }}
                      >
                        MDP
                      </span>
                      {/* Petite - LGP */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "LGP", checked: !filters.LGP },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.LGP ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.LGP ? "black" : "",
                          color: filters.LGP ? "white" : "",
                        }}
                      >
                        LGP
                      </span>
                      {/* Petite - XLP */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XLP", checked: !filters.XLP },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XLP ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XLP ? "black" : "",
                          color: filters.XLP ? "white" : "",
                        }}
                      >
                        XLP
                      </span>
                      {/* Petite - 2XLP */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "twoXLP",
                              checked: !filters.twoXLP,
                            },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.twoXLP ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.twoXLP ? "black" : "",
                          color: filters.twoXLP ? "white" : "",
                        }}
                      >
                        2XLP
                      </span>
                      {/* Petite - 3XLP */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "threeXLP",
                              checked: !filters.threeXLP,
                            },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.threeXLP ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.threeXLP ? "black" : "",
                          color: filters.threeXLP ? "white" : "",
                        }}
                      >
                        3XLP
                      </span>
                    </div>
                  </div>
                </div>
                {/* Short sizes */}
                <div>
                  <h1
                    className="text-black mt-[15px] mb-[10px]"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Short
                  </h1>
                  <div
                    className="filter-items size-dotted-border"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      className="flex flex-wrap mb-[10px]"
                      style={{ gap: "10px" }}
                    >
                      {/* Short - XSS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XSS", checked: !filters.XSS },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XSS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XSS ? "black" : "",
                          color: filters.XSS ? "white" : "",
                        }}
                      >
                        XSS
                      </span>
                      {/* Short - SMS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "SMS", checked: !filters.SMS },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.SMS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.SMS ? "black" : "",
                          color: filters.SMS ? "white" : "",
                        }}
                      >
                        SMS
                      </span>
                      {/* Short - MDS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "MDS", checked: !filters.MDS },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.MDS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.MDS ? "black" : "",
                          color: filters.MDS ? "white" : "",
                        }}
                      >
                        MDS
                      </span>
                      {/* Short - LGS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "LGS", checked: !filters.LGS },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.LGS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.LGS ? "black" : "",
                          color: filters.LGS ? "white" : "",
                        }}
                      >
                        LGS
                      </span>
                      {/* Short - XLS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XLS", checked: !filters.XLS },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XLS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XLS ? "black" : "",
                          color: filters.XLS ? "white" : "",
                        }}
                      >
                        XLS
                      </span>
                      {/* Short - 2XLS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "twoXLS",
                              checked: !filters.twoXLS,
                            },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.twoXLS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.twoXLS ? "black" : "",
                          color: filters.twoXLS ? "white" : "",
                        }}
                      >
                        2XLS
                      </span>
                      {/* Short - 3XLS */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "threeXLS",
                              checked: !filters.threeXLS,
                            },
                          })
                        }
                        className={`size-label cursor-pointer mb-[10px] ${
                          filters.threeXLS ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.threeXLS ? "black" : "",
                          color: filters.threeXLS ? "white" : "",
                        }}
                      >
                        3XLS
                      </span>
                    </div>
                  </div>
                </div>
                {/* Tall sizes */}
                <div>
                  <h1
                    className="text-black mt-[15px] mb-[10px]"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Tall
                  </h1>
                  <div
                    className="filter-items"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      className="flex flex-wrap mb-[5px]"
                      style={{ gap: "10px" }}
                    >
                      {/* Tall - XST */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XST", checked: !filters.XST },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XST ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XST ? "black" : "",
                          color: filters.XST ? "white" : "",
                        }}
                      >
                        XST
                      </span>
                      {/* Tall - SMT */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "SMT", checked: !filters.SMT },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.SMT ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.SMT ? "black" : "",
                          color: filters.SMT ? "white" : "",
                        }}
                      >
                        SMT
                      </span>
                      {/* Tall - MDT */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "MDT", checked: !filters.MDT },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.MDT ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.MDT ? "black" : "",
                          color: filters.MDT ? "white" : "",
                        }}
                      >
                        MDT
                      </span>
                      {/* Tall - LGT */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "LGT", checked: !filters.LGT },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.LGT ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.LGT ? "black" : "",
                          color: filters.LGT ? "white" : "",
                        }}
                      >
                        LGT
                      </span>
                      {/* Tall - XLT */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: { name: "XLT", checked: !filters.XLT },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.XLT ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.XLT ? "black" : "",
                          color: filters.XLT ? "white" : "",
                        }}
                      >
                        XLT
                      </span>
                      {/* Tall - 2XLT */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "twoXLT",
                              checked: !filters.twoXLT,
                            },
                          })
                        }
                        className={`size-label border border-qgray-border cursor-pointer mb-[10px] ${
                          filters.twoXLT ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.twoXLT ? "black" : "",
                          color: filters.twoXLT ? "white" : "",
                        }}
                      >
                        2XLT
                      </span>
                      {/* Tall - 3XLT */}
                      <span
                        onClick={() =>
                          checkboxHandler({
                            target: {
                              name: "threeXLT",
                              checked: !filters.threeXLT,
                            },
                          })
                        }
                        className={`size-label cursor-pointer mb-[10px] ${
                          filters.threeXLT ? "bg-black" : "text-qgray"
                        }`}
                        style={{
                          display: "inline-block",
                          width: "fit-content",
                          backgroundColor: filters.threeXLT ? "black" : "",
                          color: filters.threeXLT ? "white" : "",
                        }}
                      >
                        3XLT
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Color */}
        {/*
          <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Color</h1>
          </div>
          <div className="filter-items">
            <div className="flex space-x-[5px] flex-wrap">
              <span
                onClick={() => filterstorage("BKM")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "BKM"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                BKM
              </span>
              <span
                onClick={() => filterstorage("BLK")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "BLK"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                BLK
              </span>
              <span
                onClick={() => filterstorage("CBL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "CBL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                CBL
              </span>
              <span
                onClick={() => filterstorage("CHC")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "CHC"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                CHC
              </span>
              <span
                onClick={() => filterstorage("HPK")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "HPK"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                HPK
              </span>
              <span
                onClick={() => filterstorage("HTR")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "HTR"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                HTR
              </span>
              <span
                onClick={() => filterstorage("KHI")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "KHI"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                KHI
              </span>
              <span
                onClick={() => filterstorage("MBL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "MBL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                MBL
              </span>
              <span
                onClick={() => filterstorage("NVY")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "NVY"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                NVY
              </span>

              <span
                onClick={() => filterstorage("NYM")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "NYM"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                NYM
              </span>
              <span
                onClick={() => filterstorage("PEW")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "PEW"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                PEW
              </span>
              <span
                onClick={() => filterstorage("PPL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "PPL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                PPL
              </span>
              <span
                onClick={() => filterstorage("REDs")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "RED"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                RED
              </span>
              <span
                onClick={() => filterstorage("RYL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "RYL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                RYL
              </span>
              <span
                onClick={() => filterstorage("TEL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "TEL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                TEL
              </span>
              <span
                onClick={() => filterstorage("WHT")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "WHT"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                WHT
              </span>
              <span
                onClick={() => filterstorage("WIN")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "WIN"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                WIN
              </span>
            </div>
          </div>
        </div> 
        */}

        <button
          onClick={filterToggleHandler}
          type="button"
          className="w-10 h-10 fixed top-5 right-5 z-50 rounded lg:hidden flex justify-center items-center border border-qred text-qred"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </>
  );
}
