import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getInvoiceHeader } from "../../../actions/invoiceAction";
import { setSelectedInvoicesToRedux } from "../../../actions/paymentAction";
import "../Dashboard.css";

export default function InvoiceTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const invoiceInfo = useSelector((state) => state.getInvoiceHeader);
  const { loading, error, invoiceHeader } = invoiceInfo;

  //validate user
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // payment permission
  const paymentPermission = userInfo.result.user.permissions.Payment;

  // for multi payment
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  // set selectedInvoices in the store
  const handlePayInvoice = () => {
    if (selectedInvoices.length === 0) {
      alert("Please select the invoice you want to pay.");
      return;
    }

    console.log("selectedInvoices in handlePayInvoice:", selectedInvoices);

    // store selected invoices to redux
    dispatch(setSelectedInvoicesToRedux(selectedInvoices));

    // Navigate to PaymentTab
    navigate("/myaccount#creditPayment");
  };

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logged in ");
      console.log("userId : ", userInfo.result.user.userId);
    }

    dispatch(getInvoiceHeader(userInfo.result.user.userId));
  }, [dispatch, navigate, redirect, userInfo]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (
    invoiceHeader === undefined ||
    invoiceHeader === null ||
    invoiceHeader.length === 0
  ) {
    return <div>You invoice history is empty.</div>;
  }

  const toggleInvoiceSelection = (invoice) => {
    const { erpInvoiceId, num, balanceDue, totalAmount, customerPo } = invoice;
    const alreadySelected = selectedInvoices.some(
      (inv) => inv.erpInvoiceId === erpInvoiceId
    );

    if (alreadySelected) {
      setSelectedInvoices(
        selectedInvoices.filter((inv) => inv.erpInvoiceId !== erpInvoiceId)
      );
    } else {
      setSelectedInvoices([
        ...selectedInvoices,
        { erpInvoiceId, num, balanceDue, totalAmount, customerPo },
      ]);
    }
  };

  // invoice check box
  const isInvoiceSelected = (invoiceNumber) => {
    return selectedInvoices.some((inv) => inv.erpInvoiceId === invoiceNumber);
  };

  // 날짜 포맷 변경 함수
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  // 현재 시스템 시간과 비교 함수
  const isPastDue = (dueDateString) => {
    const currentDate = new Date();
    const dueDate = new Date(dueDateString);
    return currentDate > dueDate;
  };

  // view invoice
  const viewInvoice = (erpInvoiceId, invoiceNum) => {
    // for moving dashboard tab (update parent component's state)
    props.setSelectedErpInvoiceId(erpInvoiceId);
    props.setSelectedInvoiceNum(invoiceNum);
  };

  return (
    <>
      {/* Invoice Title */}
      <div className="flex justify-center items-center text-2xl font-semibold p-4 invoice-title">
        INVOICE
      </div>

      <div className="support-tab w-full ">
        {/* Accounting header Container 
        <div className="new-container w-full flex justify-around">
          <div className="square-container bg-blue-200 w-20 h-20"></div>
          <div className="square-container bg-green-200 w-20 h-20"></div>
          <div className="square-container bg-yellow-200 w-20 h-20"></div>
          <div className="square-container bg-red-200 w-20 h-20"></div>
        </div> */}

        <div className="w-[180px] h-[50px] mb-4">
          {paymentPermission === 1 && (
            <button
              type="button"
              className="yellow-btn"
              onClick={handlePayInvoice}
            >
              <div className="w-full text-sm font-semibold">PAY INVOICE</div>
            </button>
          )}
        </div>

        <div className="relative w-full overflow-x-auto sm:rounded-lg responsive-table-container">
          <table className="w-full text-sm text-left text-gray-500">
            <tbody>
              {/* Table header */}
              <tr className="text-sm text-qblack font-semibold bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom ">
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Invoice#
                </td>
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  PO#
                </td>
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Date
                </td>
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Due Date
                </td>
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Total <br /> Amount
                </td>
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Due <br />
                  Amount
                </td>
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Status
                </td>
                <td className="py-5 whitespace-nowrap  text-center">Action</td>
              </tr>
              {/* Table header end */}
              {invoiceHeader.map((invoice, index) => (
                <tr
                  className="bg-white hover:bg-gray-50"
                  key={invoice.erpInvoiceId}
                >
                  <td className="text-center py-3 px-2">
                    <span
                      className={`text-sm whitespace-nowrap ${
                        isPastDue(invoice.dueDate)
                          ? "text-red-500"
                          : "text-qgray"
                      }`}
                    >
                      {invoice.num}
                    </span>
                  </td>
                  <td className="text-center py-3 px-2">
                    <span
                      className={`text-sm whitespace-nowrap ${
                        isPastDue(invoice.dueDate)
                          ? "text-red-500"
                          : "text-qgray"
                      }`}
                    >
                      {invoice.customerPo}
                    </span>
                  </td>
                  <td className="text-center py-3 px-2">
                    <span
                      className={`text-sm whitespace-nowrap ${
                        isPastDue(invoice.dueDate)
                          ? "text-red-500"
                          : "text-qgray"
                      }`}
                    >
                      {formatDate(invoice.invoiceDate)}
                    </span>
                  </td>
                  <td className="text-center py-3 px-2">
                    <span
                      className={`text-sm whitespace-nowrap ${
                        isPastDue(invoice.dueDate)
                          ? "text-red-500"
                          : "text-qgray"
                      }`}
                    >
                      {formatDate(invoice.dueDate)}
                    </span>
                  </td>
                  <td className="text-center py-3 px-2">
                    <span
                      className={`text-sm whitespace-nowrap ${
                        isPastDue(invoice.dueDate)
                          ? "text-red-500"
                          : "text-qgray"
                      }`}
                    >
                      {invoice.totalAmount}
                    </span>
                  </td>
                  <td className="text-center py-3 px-2">
                    <span
                      className={`text-sm whitespace-nowrap ${
                        isPastDue(invoice.dueDate)
                          ? "text-red-500"
                          : "text-qgray"
                      }`}
                    >
                      {invoice.balanceDue}
                    </span>
                  </td>
                  <td className="text-center py-3 px-2">
                    <span
                      className={`text-sm whitespace-nowrap ${
                        isPastDue(invoice.dueDate)
                          ? "text-red-500"
                          : "text-qgray"
                      }`}
                    >
                      {isPastDue(invoice.dueDate) ? "PastDue" : "Current"}
                    </span>
                  </td>
                  <td className="text-center py-3 flex space-x-2.5 justify-center">
                    {/* "View" 버튼 */}
                    <Link
                      type="button"
                      className="border border-qgray w-[60px] h-[25px] rounded flex justify-center items-center bg-blue-500 view-link"
                      onClick={() =>
                        viewInvoice(invoice.erpInvoiceId, invoice.num)
                      }
                      to="/myaccount#viewInvoice"
                    >
                      <span className="text-center">View</span>
                    </Link>

                    {/* 체크박스 버튼 */}
                    {paymentPermission === 1 && (
                      <input
                        type="checkbox"
                        className={` w-[30px] h-[20px] `}
                        checked={isInvoiceSelected(invoice.erpInvoiceId)}
                        onChange={() => toggleInvoiceSelection(invoice)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
