import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import datas from "../../data/products.json";
import BreadcrumbCom from "../BreadcrumbCom";
import Layout from "../Partials/Layout";
import IcoAddress from "./icons/IcoAddress";
import IcoCart from "./icons/IcoCart";
import IcoDashboard from "./icons/IcoDashboard";
import IcoLogout from "./icons/IcoLogout";
import IcoLove from "./icons/IcoLove";
import IcoInvoice from "./icons/IcoInvoice";
import IcoStatement from "./icons/IcoStatement";
import IcoPassword from "./icons/IcoPassword";
import IcoPayment from "./icons/IcoPayment";
import IcoPeople from "./icons/IcoPeople";
import IcoReviewHand from "./icons/IcoReviewHand";
import IcoCustomerRole from "./icons/IcoCustomerRole";
import IcoSupport from "./icons/IcoSupport";
import AddressesTab from "./tabs/AddressesTab";
import Dashboard from "./tabs/Dashboard";
import OrderTab from "./tabs/OrderTab";
import PasswordTab from "./tabs/PasswordTab";
import Payment from "./tabs/Payment";
import ProfileTab from "./tabs/ProfileTab";
import ReviewTab from "./tabs/ReviewTab";
import SupportTab from "./tabs/SupportTab";
import WishlistTab from "./tabs/WishlistTab";
import InvoiceTab from "./tabs/InvoiceTab";
import DashHeader from "./tabs/Header";
import { getMyAccount, signout } from "../../actions/userAuthAction";
import ViewInvoiceTab from "./tabs/ViewInvoiceTab";
import PaymentTab from "./tabs/PaymentTab";
import { setCreditBalanceToRedux } from "../../actions/paymentAction";
import IcoCustomerUser from "./icons/IcoCustomerUser";
import StatementTab from "./tabs/StatementTab";
import ViewStatementTab from "./tabs/ViewStatementTab";

export default function MyAccountIndex() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //validate user
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // Initialize states for permissions
  const [invoicePermission, setInvoicePermission] = useState(null);
  const [statementPermission, setStatementPermission] = useState(null);
  const [wishListPermission, setWishListPermission] = useState(null);
  const [ordersPermission, setOrdersPermission] = useState(null);
  const [userRolesPermission, setUserRolesPermission] = useState(null);
  const [subUsersPermission, setSubUsersPermission] = useState(null);

  //myaccount information
  const myAccountInfo = useSelector((state) => state.getMyAccount);
  const { loading, error, myAccount } = myAccountInfo;
  const userProfileInfo = myAccount?.result?.result?.profile;
  const userAccountingInfo = myAccount?.result?.result?.accounting;
  const userOrderInfo = myAccount?.result?.result?.order;
  const userId = userInfo?.result?.result?.profile;

  // view invoice tab
  const [selectedErpInvoiceId, setSelectedErpInvoiceId] = useState(null);
  const [selectedInvoiceNum, setSelectedInvoiceNum] = useState(null);

  // view statement tab
  const [selectedStatementId, setSelectedStatementId] = useState(null);

  //dashboard
  const [switchDashboard, setSwitchDashboard] = useState(false);
  const [active, setActive] = useState("dashboard");
  const [paths, setPaths] = useState([
    { name: "home", path: "/" },
    { name: "my account", path: "/myaccount" },
  ]);

  const signOutHandler = () => {
    localStorage.removeItem("IRGAccesstoken");
    localStorage.removeItem("IRGtokenExpiry");
    dispatch(signout());
    navigate("/Login");
  };

  useEffect(() => {
    const getHashContent = location.hash.split("#");

    setActive(
      getHashContent && getHashContent.length > 1
        ? getHashContent[1]
        : "dashboard"
    );

    const newPaths = [
      { name: "home", path: "/" },
      { name: "my account", path: "/myaccount" },
      { name: getHashContent[1], path: `/myaccount#${getHashContent[1]}` },
    ];
    setPaths(newPaths);
  }, [location.hash]);

  //call API after user validation
  useEffect(() => {
    if (!userInfo) {
      // alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logged in ");

      // Set permissions
      setInvoicePermission(userInfo.result?.user.permissions.Invoice);
      setStatementPermission(userInfo.result?.user.permissions.Statement);
      setWishListPermission(userInfo.result?.user.permissions.WishList);
      setOrdersPermission(userInfo.result?.user.permissions.Orders);
      setUserRolesPermission(userInfo.result?.user.permissions.userRoles);
      setSubUsersPermission(userInfo.result?.user.permissions.SubUsers);
    }

    console.log("invoicePermission : ", invoicePermission);

    dispatch(getMyAccount());
  }, [dispatch, navigate, redirect, userInfo]);

  useEffect(() => {
    if (myAccount) {
      dispatch(
        setCreditBalanceToRedux(
          myAccount?.result?.result?.accounting?.creditBalance
        )
      );
    }
  }, [myAccount, myAccount?.result?.result?.accounting?.creditBalance]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="profile-page-wrapper w-full">
        <div className="container-x mx-auto">
          <div className="w-full my-10">
            {/*<BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "my account", path: "/myaccount" },
              ]}
            />*/}
            <BreadcrumbCom paths={paths} />
            {/* Container for accounting info */}
            {invoicePermission === 1 ? (
              <DashHeader
                myProfile={userProfileInfo}
                myAccounting={userAccountingInfo}
              />
            ) : null}
            {/* Container for dashboard */}
            <div className="w-full bg-white px-10 py-9 mt-4">
              <div className="title-area w-full flex justify-between items-center">
                <h1 className="text-[22px] font-bold text-qblack">
                  Your Dashboard
                </h1>
                {/*  <div className="switch-dashboard flex space-x-3 items-center">
                  <p className="text-qgray text-base">Switch Dashboard</p>
                  <button
                    onClick={() => setSwitchDashboard(!switchDashboard)}
                    type="button"
                    className="w-[73px] h-[31px] border border-[#D9D9D9] rounded-full relative "
                  >
                    <div
                      className={`w-[23px] h-[23px] bg-qblack rounded-full absolute top-[3px] transition-all duration-300 ease-in-out ${
                        switchDashboard ? "left-[44px]" : "left-[4px]"
                      }`}
                    ></div>
                  </button>
                    </div> */}
              </div>
              <div className="profile-wrapper w-full mt-8 flex space-x-10">
                {/* Dashboard tab */}
                {/* <div className="nav-tabs w-[200px] min-h-[600px] border-r border-[rgba(0, 0, 0, 0.1)] md:pr-10"> */}
                <div className="nav-tabs w-[200px] min-h-[600px] border-r border-[rgba(0, 0, 0, 0.1)]">
                  <div className="flex flex-col space-y-5 w-full">
                    {/*   <div className="item group "> */}
                    <div
                      className={`dash-item ${
                        active === "dashboard" ? "active-item" : ""
                      }`}
                    >
                      <Link to="/myaccount#dashboard">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack ">
                          <span>
                            <IcoDashboard />
                          </span>
                          <span
                            className={` ${
                              active === "dashboard" ? "active-item-font" : ""
                            }`}
                          >
                            My Account
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`dash-item  ${
                        active === "profile" ? "active-item" : ""
                      }`}
                    >
                      <Link to="/myaccount#profile">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoPeople />
                          </span>
                          <span
                            className={` ${
                              active === "profile" ? "active-item-font" : ""
                            }`}
                          >
                            Profile
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`dash-item  ${
                        active === "address" ? "active-item" : ""
                      }`}
                    >
                      <Link to="/myaccount#address">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoAddress />
                          </span>
                          <span
                            className={` ${
                              active === "address" ? "active-item-font" : ""
                            }`}
                          >
                            Address
                          </span>
                        </div>
                      </Link>
                    </div>
                    {ordersPermission === 1 && (
                      <div
                        className={`dash-item  ${
                          active === "order" ? "active-item" : ""
                        }`}
                      >
                        <Link to="/myaccount#order">
                          <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                            <span>
                              <IcoCart />
                            </span>
                            <span
                              className={` ${
                                active === "order" ? "active-item-font" : ""
                              }`}
                            >
                              Order History
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}

                    {/* <div className="item group">
                      <Link to="/myaccount#payment">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoPayment />
                          </span>
                          <span className=" font-normal text-base">
                            Payment Method
                          </span>
                        </div>
                      </Link>
                    </div> /*}
                    
                    <div className="item group">
                      <Link to="/myaccount#order">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoCart />
                          </span>
                          <span className=" font-normal text-base">Order</span>
                        </div>
                      </Link>
                    </div> 
                    */}

                    {/* Check invoice permission */}
                    {/* {wishListPermission === 1 && (
                      <div className="item group">
                        <Link to="/myaccount#wishlist">
                          <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                            <span>
                              <IcoLove />
                            </span>
                            <span className=" font-normal text-base">
                              Wishlist
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                     */}
                    {/* <div className="item group">
                      <Link to="/myaccount#review">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoReviewHand />
                          </span>
                          <span className=" font-normal text-base">
                            Reviews
                          </span>
                        </div>
                      </Link>
                  </div> */}

                    {/* Check invoice permission */}
                    {invoicePermission === 1 && (
                      <div
                        className={`dash-item  ${
                          active === "invoice" ? "active-item" : ""
                        }`}
                      >
                        <Link to="/myaccount#invoice">
                          <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                            <span>
                              <IcoInvoice />
                            </span>
                            <span
                              className={` ${
                                active === "invoice" ? "active-item-font" : ""
                              }`}
                            >
                              Invoice
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                    {/* Check statement permission */}
                    {statementPermission === 1 && (
                      <div
                        className={`dash-item  ${
                          active === "statement" ? "active-item" : ""
                        }`}
                      >
                        <Link to="/myaccount#statement">
                          <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                            <span>
                              <IcoStatement />
                            </span>
                            <span
                              className={` ${
                                active === "statement" ? "active-item-font" : ""
                              }`}
                            >
                              Statement
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                    <div
                      className={`dash-item  ${
                        active === "password" ? "active-item" : ""
                      }`}
                    >
                      <Link to="/myaccount#password">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoPassword />
                          </span>
                          <span
                            className={` ${
                              active === "password" ? "active-item-font" : ""
                            }`}
                          >
                            Password
                          </span>
                        </div>
                      </Link>
                    </div>

                    {/* Check UserRole permission */}
                    {userRolesPermission === 1 && (
                      <div className="item group">
                        <Link to="/CustomerRoles">
                          <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                            <span>
                              <IcoCustomerRole />
                            </span>
                            <span className=" font-normal text-base">
                              User Roles
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}

                    {/* Check Subuser permission */}
                    {subUsersPermission === 1 && (
                      <div className="item group">
                        <Link to="/CustomerUsers">
                          <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                            <span>
                              <IcoCustomerUser />
                            </span>
                            <span className=" font-normal text-base">
                              Cusotmer User
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                    {/*
                    <div className="item group">
                      <Link to="/myaccount#support">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoSupport />
                          </span>
                          <span className=" font-normal text-base">
                            Support Ticket
                          </span>
                        </div>
                      </Link>
                    </div>
                    */}
                    <div
                      className={`dash-item  ${
                        active === "contact" ? "active-item" : ""
                      }`}
                    >
                      <Link to="/contact">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoSupport />
                          </span>
                          <span
                            className={` ${
                              active === "contact" ? "active-item-font" : ""
                            }`}
                          >
                            Customer Service
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className={`dash-item  `}>
                      {/* <Link to="/myaccount#profile">*/}
                      <button
                        onClick={signOutHandler}
                        className="focus:outline-none"
                      >
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoLogout />
                          </span>
                          <span
                            className={` ${
                              active === "logout" ? "active-item-font" : ""
                            }`}
                          >
                            Logout
                          </span>
                        </div>
                        {/* </Link> */}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="item-body dashboard-wrapper w-full ">
                    {loading ? (
                      <div>Loading...</div> // 로딩 중일 때의 UI
                    ) : active === "dashboard" ? (
                      <Dashboard
                        myProfile={userProfileInfo}
                        myOrder={userOrderInfo}
                      />
                    ) : active === "profile" ? (
                      <>
                        <ProfileTab myProfile={userProfileInfo} />
                      </>
                    ) : active === "payment" ? (
                      <>
                        <Payment />
                      </>
                    ) : active === "creditPayment" ? (
                      <>
                        <PaymentTab />
                      </>
                    ) : active === "order" ? (
                      <>
                        <OrderTab />
                      </>
                    ) : active === "wishlist" ? (
                      <>
                        <WishlistTab />
                      </>
                    ) : active === "address" ? (
                      <>
                        <AddressesTab />
                      </>
                    ) : active === "invoice" ? (
                      <>
                        <InvoiceTab
                          setSelectedErpInvoiceId={setSelectedErpInvoiceId}
                          setSelectedInvoiceNum={setSelectedInvoiceNum}
                        />
                      </>
                    ) : active === "viewInvoice" ? (
                      <>
                        <ViewInvoiceTab
                          erpInvoiceId={selectedErpInvoiceId}
                          invoiceNum={selectedInvoiceNum}
                        />
                      </>
                    ) : active === "statement" ? (
                      <>
                        <StatementTab
                          setSelectedStatementId={setSelectedStatementId}
                        />
                      </>
                    ) : active === "viewStatement" ? (
                      <>
                        <ViewStatementTab statementId={selectedStatementId} />
                      </>
                    ) : active === "password" ? (
                      <>
                        <PasswordTab />
                      </>
                    ) : active === "support" ? (
                      <>
                        <SupportTab />
                      </>
                    ) : (
                      /*  
                    : active === "review" ? (
                      <>
                        <ReviewTab products={datas.products} />
                      </>
                    ) 
                    */
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
