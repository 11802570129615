import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSubuserList } from "../../../actions/subuserAction";

import BreadcrumbCom from "../../BreadcrumbCom";
import EmptyCardError from "../../EmptyCardError";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import UserTable from "./UserTable";

export default function CustomerUsers({ cart = true }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get user
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  // get subusers
  const customerSubusers = useSelector((state) => state.getSubuserList);
  const { loading, error, subusers } = customerSubusers; //payload로 받은 데이터를 customerRoles.roles 안에 저장 (data from API)

  const adminUserId = subusers?.result.adminUserId;
  const userId = subusers?.result.userId;
  const subuserList = subusers?.result.subuserList;

  // user info
  const loginId = userInfo?.result.user.loginId;
  const userLevel = userInfo?.result.user.parentAccount;
  const userEmail = userInfo?.result.user.contactEmail;

  // get login info
  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logined ");
    }
    dispatch(getSubuserList());
  }, [dispatch, userInfo, navigate, redirect]);

  useEffect(() => {
    console.log("subuserList", subuserList);
  }, [subuserList]);

  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      {cart === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "CustomerUsers", path: "/customerusers" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full h-25">
            <PageTitle
              title="Sub-Users"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Sub-Users", path: "/customerusers" },
              ]}
            />
          </div>

          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">
              <UserTable
                isLoading={loading}
                subuserList={subuserList}
                loginId={loginId}
                userLevel={userLevel}
                userEmail={userEmail}
              />

              <div className="w-full mt-[30px] flex sm:justify-end"></div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
