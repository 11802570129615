import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../../../actions/roleAction";
import BreadcrumbCom from "../../BreadcrumbCom";
import EmptyCardError from "../../EmptyCardError";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import RoleEditForm from "./RoleEditForm";

export default function EditRole({ cart = true }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //validate user
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  //get URL parameter
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const roleName = params.get("roleName");
  const adminUserId = params.get("adminUserId");

  // get initial permissions from API
  const permissionsFromAPI = useSelector((state) => state.getPermissions);
  const { loading, error, permissionsFromAPI: perms } = permissionsFromAPI;
  console.log("permissionsFromAPI : ", permissionsFromAPI);

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logined ");
    }
    dispatch(getPermissions(id));

    console.log("(EditRole) id: ", id);
    console.log("(EditRole) roleName: ", roleName);
    console.log("(EditRole) adminUserId: ", adminUserId);
  }, [dispatch, navigate, redirect, userInfo]);

  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      {cart === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "User Roles", path: "/customerroles" },
                { name: "Edit Customer User Role", path: "/editrole" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full h-25">
            <PageTitle
              title="Edit Customer User Role"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "User Roles", path: "/customerroles" },
                { name: "Edit Customer User Role", path: "/editrole" },
              ]}
            />
          </div>

          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">
              <RoleEditForm
                roleIdProp={id}
                roleNameProp={roleName}
                adminUserIdProp={adminUserId}
                permissionsProp={permissionsFromAPI}
              />
              <div className="w-full mt-[30px] flex sm:justify-end"></div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
