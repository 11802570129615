import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../../actions/roleAction";

import BreadcrumbCom from "../../BreadcrumbCom";
import EmptyCardError from "../../EmptyCardError";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import AddUserForm from "./AddUserForm";

export default function AddUser({ cart = true }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get user
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  // get roles
  const customerRoles = useSelector((state) => state.getRoles);
  const { loading, error, roles } = customerRoles; //payload로 받은 데이터를 customerRoles.roles 안에 저장 (data from API)

  const roleList = roles?.result.roleList;

  // get login info
  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logined ");
    }
    dispatch(getRoles());
  }, [dispatch, userInfo, navigate, redirect]);

  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      {cart === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "Sub-Users", path: "/customerusers" },
                { name: "Manage Sub-Users", path: "/adduser" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full h-25">
            <PageTitle
              title="Manage Sub Users"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Sub-Users", path: "/customerusers" },
                { name: "Manage Sub-Users", path: "/adduser" },
              ]}
            />
          </div>

          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">
              <AddUserForm className="mb-[30px]" roleList={roleList} />

              <div className="w-full mt-[30px] flex sm:justify-end"></div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
