import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import BreadcrumbCom from "../../BreadcrumbCom";
import "../Dashboard.css";

const NewOrderContainer = ({ myOrder }) => (
  <div className="inner-container">
    <div className="icon-with-text">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="12" cy="12" r="12" fill="#04ADD1" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1816 10.9999C10.1816 10.6685 10.4258 10.3999 10.7271 10.3999H15.0907C15.392 10.3999 15.6362 10.6685 15.6362 10.9999C15.6362 11.3313 15.392 11.5999 15.0907 11.5999H10.7271C10.4258 11.5999 10.1816 11.3313 10.1816 10.9999Z"
          fill="#FBFBFB"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1816 13.3998C10.1816 13.0684 10.4258 12.7998 10.7271 12.7998H15.0907C15.392 12.7998 15.6362 13.0684 15.6362 13.3998C15.6362 13.7312 15.392 13.9998 15.0907 13.9998H10.7271C10.4258 13.9998 10.1816 13.7312 10.1816 13.3998Z"
          fill="#FBFBFB"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 10.9999C8 10.6685 8.24421 10.3999 8.54545 10.3999H9.09091C9.39216 10.3999 9.63636 10.6685 9.63636 10.9999C9.63636 11.3313 9.39216 11.5999 9.09091 11.5999H8.54545C8.24421 11.5999 8 11.3313 8 10.9999Z"
          fill="#FBFBFB"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 13.3998C8 13.0684 8.24421 12.7998 8.54545 12.7998H9.09091C9.39216 12.7998 9.63636 13.0684 9.63636 13.3998C9.63636 13.7312 9.39216 13.9998 9.09091 13.9998H8.54545C8.24421 13.9998 8 13.7312 8 13.3998Z"
          fill="#FBFBFB"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17 9H7L7 17H17V9ZM6 9V7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V9V17C18 17.5523 17.5523 18 17 18H7C6.44772 18 6 17.5523 6 17V9Z"
          fill="#FBFBFB"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 7C18 6.44772 17.5523 6 17 6H7C6.44772 6 6 6.44772 6 7V17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V7ZM7 8H17V7L7 7L7 8ZM7 9L7 17H17V9H7Z"
          fill="#FBFBFB"
        />
      </svg>
      <div style={{ marginLeft: "8px" }}>New Orders</div>
    </div>
    <div className="number" style={{ marginTop: "4px" }}>
      {myOrder ? myOrder.numOfInprogress : "-"}
    </div>
  </div>
);
const ShippedContainer = ({ myOrder }) => (
  <div className="inner-container">
    <div className="icon-with-text">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#04ADD1" />
        <path
          d="M11.6591 8.54732L11.8811 8.74912C11.881 8.74921 11.8809 8.74931 11.8808 8.7494L11.8811 8.74912L11.6591 8.54732ZM11.6591 8.54732L11.4371 8.74912C11.4372 8.74921 11.4373 8.74931 11.4374 8.7494L11.4371 8.74912L11.6591 8.54732ZM16.0909 17.375C16.9767 17.375 17.6136 16.6027 17.6136 15.75C17.6136 14.8973 16.9767 14.125 16.0909 14.125C15.2051 14.125 14.5682 14.8973 14.5682 15.75C14.5682 16.6027 15.2051 17.375 16.0909 17.375ZM6.81614 14.5113L6.44339 14.1781L6.81615 14.5113C7.10399 14.1893 7.49246 14 7.90909 14C8.32573 14 8.71419 14.1893 9.00204 14.5113C9.12902 14.6534 9.31748 14.75 9.53103 14.75H13.8455C14.2321 14.75 14.5455 14.4366 14.5455 14.05V7.7C14.5455 7.3134 14.2321 7 13.8455 7H6.06364C5.67704 7 5.36364 7.3134 5.36364 7.7V14.05C5.36364 14.4366 5.67704 14.75 6.06364 14.75H6.28715C6.50071 14.75 6.68916 14.6534 6.81614 14.5113ZM17.3271 9.70065C17.2303 9.6516 17.1222 9.625 17.0106 9.625H15.6091C15.2225 9.625 14.9091 9.9384 14.9091 10.325V11.8C14.9091 12.1866 15.2225 12.5 15.6091 12.5H18.0619C18.4717 12.5 18.7582 12.1664 18.763 11.8067L19 12.1542V15.25H18.3364C17.9401 15.25 17.6574 15.5659 17.63 15.9103C17.5557 16.8438 16.8601 17.5 16.0909 17.5C15.3217 17.5 14.6261 16.8438 14.5518 15.9103C14.5244 15.5659 14.2417 15.25 13.8455 15.25H10.1545C9.7583 15.25 9.47561 15.5659 9.4482 15.9103C9.37388 16.8438 8.67827 17.5 7.90909 17.5C7.13991 17.5 6.4443 16.8438 6.36998 15.9103C6.34257 15.5659 6.05988 15.25 5.66364 15.25H5V7.5C5 6.89773 5.42674 6.5 5.86364 6.5H14.9091V8.8C14.9091 9.1866 15.2225 9.5 15.6091 9.5H17.1903L17.3271 9.70065ZM7.90909 17.375C8.79492 17.375 9.43182 16.6027 9.43182 15.75C9.43182 14.8973 8.79492 14.125 7.90909 14.125C7.02326 14.125 6.38636 14.8973 6.38636 15.75C6.38636 16.6027 7.02326 17.375 7.90909 17.375ZM7.903 10.875L8.25 10.4933L8.75477 11.0485C9.0325 11.3541 9.51295 11.3541 9.79069 11.0485L11.6591 8.9933L12.0061 9.375L9.27273 12.3817L7.903 10.875Z"
          fill="white"
          stroke="white"
        />
      </svg>

      <div style={{ marginLeft: "8px" }}>Shipped</div>
    </div>
    <div className="number" style={{ marginTop: "4px" }}>
      {myOrder ? myOrder.numOfFulfilled : "-"}
    </div>
  </div>
);
const CanceledContainer = ({ myOrder }) => (
  <div className="inner-container">
    <div className="icon-with-text">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <circle cx="12" cy="12.8928" r="12" fill="#04ADD1" />
        <path
          d="M11.9091 16.4928H12.0011C11.9978 16.5931 11.9977 16.6932 12.0006 16.7928H7.18182C7.0172 16.7928 6.84847 16.7213 6.71612 16.5757C6.58232 16.4286 6.5 16.2196 6.5 15.9928V8.19282C6.5 7.70805 6.85174 7.39282 7.18182 7.39282H15.4545C15.6192 7.39282 15.7879 7.46433 15.9202 7.60992C16.054 7.75709 16.1364 7.96608 16.1364 8.19282V12.2418C16.0757 12.2404 16.0151 12.2404 15.9545 12.242V12.0928V9.49282V8.99282H15.4545H11.9091H11.4091V9.49282V12.0928V12.5928H11.9091H14.4607C14.2767 12.678 14.0981 12.7781 13.9264 12.8928H11.9091H11.4091V13.3928V15.9928V16.4928H11.9091ZM7.18182 8.99282H6.68182V9.49282V12.0928V12.5928H7.18182H10.7273H11.2273V12.0928V9.49282V8.99282H10.7273H7.18182ZM7.18182 12.8928H6.68182V13.3928V15.9928V16.4928H7.18182H10.7273H11.2273V15.9928V13.3928V12.8928H10.7273H7.18182Z"
          fill="#04ADD1"
          stroke="white"
        />
      </svg>
      <div style={{ marginLeft: "8px" }}>Canceled</div>
    </div>
    <div className="number" style={{ marginTop: "4px" }}>
      {myOrder ? myOrder.numOfCanceled : "-"}
    </div>
  </div>
);

export default function Dashboard({ myProfile, myOrder }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // validate user
  const { search } = useLocation(); // search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logged in ");
      console.log("username : ", userInfo.result.user.loginId);
    }
  }, [dispatch, navigate, redirect, userInfo]);

  return (
    <>
      <div style={{ marginLeft: "30px" }}>
        <div className="dashboard-main-content">
          {/* welcom message */}
          <div className="w-full">
            <div>
              <div className="welcome-msg">
                Hello, {myProfile ? myProfile.companyName : "-"}
              </div>
              <div className="order-status-msg"> Order Status</div>
            </div>
          </div>
          {/* order status */}
          <div className="parent-container">
            <div className="main-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="230"
                height="114"
                viewBox="0 0 230 114"
                fill="none"
              >
                <path
                  d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5H220C225.247 0.5 229.5 4.7533 229.5 10V104C229.5 109.247 225.247 113.5 220 113.5H10C4.75329 113.5 0.5 109.247 0.5 104V10Z"
                  fill="var(--Base-White, #FFF)"
                  stroke="#E4E4E4"
                />
              </svg>
              <NewOrderContainer myOrder={myOrder} />
            </div>
            <div className="main-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="230"
                height="114"
                viewBox="0 0 230 114"
                fill="none"
              >
                <path
                  d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5H220C225.247 0.5 229.5 4.7533 229.5 10V104C229.5 109.247 225.247 113.5 220 113.5H10C4.75329 113.5 0.5 109.247 0.5 104V10Z"
                  fill="var(--Base-White, #FFF)"
                  stroke="#E4E4E4"
                />
              </svg>
              <ShippedContainer myOrder={myOrder} />
            </div>
            <div className="main-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="230"
                height="114"
                viewBox="0 0 230 114"
                fill="none"
              >
                <path
                  d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5H220C225.247 0.5 229.5 4.7533 229.5 10V104C229.5 109.247 225.247 113.5 220 113.5H10C4.75329 113.5 0.5 109.247 0.5 104V10Z"
                  fill="var(--Base-White, #FFF)"
                  stroke="#E4E4E4"
                />
              </svg>
              <CanceledContainer myOrder={myOrder} />
            </div>
          </div>
          {/* <div className="quick-view-grid w-full flex justify-between items-center mt-3 ">
           <div className="qv-item w-[252px] h-[208px] bg-qblack group hover:bg-qyellow transition-all duration-300 ease-in-out p-6">
            <div className="w-[62px] h-[62px] rounded bg-white flex justify-center items-center">
              <span>
                <svg
                  width="36"
                  height="37"
                  viewBox="0 0 36 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.4473 8.03086C32.482 8.37876 32.5 8.73144 32.5 9.08829C32.5 14.919 27.7564 19.6625 21.9258 19.6625C16.0951 19.6625 11.3516 14.919 11.3516 9.08829C11.3516 8.73144 11.3695 8.37876 11.4042 8.03086H8.98055L8.05537 0.628906H0.777344V2.74375H6.18839L8.56759 21.7774H34.1868L35.8039 8.03086H32.4473Z"
                    fill="#FFBB38"
                  />
                  <path
                    d="M9.09669 26.0074H6.06485C4.31566 26.0074 2.89258 27.4305 2.89258 29.1797C2.89258 30.9289 4.31566 32.352 6.06485 32.352H6.24672C6.12935 32.6829 6.06485 33.0386 6.06485 33.4094C6.06485 35.1586 7.48793 36.5816 9.23711 36.5816C11.4247 36.5816 12.9571 34.4091 12.2274 32.352H22.1081C21.377 34.413 22.9157 36.5816 25.0985 36.5816C26.8476 36.5816 28.2707 35.1586 28.2707 33.4094C28.2707 33.0386 28.2061 32.6829 28.0888 32.352H30.3856V30.2371H6.06485C5.48178 30.2371 5.00742 29.7628 5.00742 29.1797C5.00742 28.5966 5.48178 28.1223 6.06485 28.1223H33.4407L33.9384 23.8926H8.83233L9.09669 26.0074Z"
                    fill="#FFBB38"
                  />
                  <path
                    d="M21.9262 17.5477C26.5907 17.5477 30.3856 13.7528 30.3856 9.08829C30.3856 4.42378 26.5907 0.628906 21.9262 0.628906C17.2616 0.628906 13.4668 4.42378 13.4668 9.08829C13.4668 13.7528 17.2617 17.5477 21.9262 17.5477ZM20.8688 5.91602H22.9836V8.6503L24.7886 10.4554L23.2932 11.9508L20.8687 9.5262V5.91602H20.8688Z"
                    fill="#FFBB38"
                  />
                </svg>
              </span>
            </div>
            <p className="custom-text-size text-white group-hover:text-qblacktext mt-5">
              New Orders
            </p>
            <span className="custom-font-size text-white group-hover:text-qblacktext font-bold leading-none mt-1 block">
              {myOrder ? myOrder.numOfInprogress : "-"}
            </span>
          </div>  
          <div className="qv-item w-[252px] h-[208px] bg-qblack group hover:bg-qyellow transition-all duration-300 ease-in-out p-6">
            <div className="w-[62px] h-[62px] rounded bg-white flex justify-center items-center">
              <span>
                <svg
                  width="33"
                  height="27"
                  viewBox="0 0 33 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30.2253 12.8816H29.4827L28.6701 9.36514C28.376 8.10431 27.2552 7.22168 25.9662 7.22168H21.8474V3.84528C21.8474 2.03804 20.3764 0.581055 18.5831 0.581055H3.17237C1.46313 0.581055 0.0761719 1.96801 0.0761719 3.67717V20.0967C0.0761719 21.8058 1.46313 23.1928 3.17237 23.1928H4.29313C4.89555 25.1962 6.74485 26.6533 8.93037 26.6533C11.1159 26.6533 12.9792 25.1962 13.5816 23.1928C13.8455 23.1928 20.3459 23.1928 20.1942 23.1928C20.7966 25.1962 22.6459 26.6533 24.8315 26.6533C27.031 26.6533 28.8803 25.1962 29.4827 23.1928H30.2253C31.7663 23.1928 32.9992 21.9599 32.9992 20.4189V15.6555C32.9992 14.1145 31.7663 12.8816 30.2253 12.8816ZM8.93037 23.8513C7.78968 23.8513 6.88491 22.8969 6.88491 21.7918C6.88491 20.657 7.79558 19.7324 8.93037 19.7324C10.0652 19.7324 10.9898 20.657 10.9898 21.7918C10.9898 22.9151 10.0692 23.8513 8.93037 23.8513ZM13.9739 8.06224L9.79897 11.3125C9.20227 11.7767 8.30347 11.6903 7.82363 11.0604L6.21247 8.94486C5.7361 8.32843 5.86222 7.4458 6.47866 6.98346C7.08107 6.50717 7.96369 6.63321 8.44006 7.24965L9.19656 8.23035L12.2507 5.84867C12.8531 5.3864 13.7357 5.48448 14.2121 6.10092C14.6884 6.71727 14.5763 7.58595 13.9739 8.06224ZM24.8315 23.8513C23.6906 23.8513 22.7861 22.8969 22.7861 21.7918C22.7861 20.657 23.7107 19.7324 24.8315 19.7324C25.9662 19.7324 26.8909 20.657 26.8909 21.7918C26.8909 22.9166 25.9683 23.8513 24.8315 23.8513ZM22.618 10.0236H25.2798C25.6021 10.0236 25.8962 10.2337 26.0083 10.542L26.8629 13.0497C27.031 13.5541 26.6667 14.0724 26.1344 14.0724H22.618C22.1976 14.0724 21.8474 13.7222 21.8474 13.3019V10.7942C21.8474 10.3739 22.1976 10.0236 22.618 10.0236Z"
                    fill="#FFBB38"
                  />
                </svg>
              </span>
            </div>
            <p className="custom-text-size text-white group-hover:text-qblacktext mt-5">
              Shipped
            </p>
            <span className="custom-font-size text-white group-hover:text-qblacktext font-bold leading-none mt-1 block">
              {myOrder ? myOrder.numOfFulfilled : "-"}
            </span>
          </div>
          <div className="qv-item w-[252px] h-[208px] bg-qblack group hover:bg-qyellow transition-all duration-300 ease-in-out p-6">
            <div className="w-[62px] h-[62px] rounded bg-white flex justify-center items-center">
              <span>
                <svg
                  width="27"
                  height="31"
                  viewBox="0 0 27 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.79749 18.4331C6.71621 20.0289 5.95627 20.8019 4.64859 23.6816C3.76653 22.8387 2.90107 22.0123 2.00953 21.1599C2.5288 20.3146 3.03267 19.4942 3.53535 18.6726C3.88035 18.1071 3.46066 17.0579 2.82282 16.899C1.88623 16.6666 0.94845 16.4426 0 16.2114C0 14.4034 0 12.6274 0 10.7827C0.921182 10.561 1.85422 10.3405 2.78489 10.1117C3.46777 9.94331 3.8922 8.90476 3.52705 8.30605C3.03385 7.49868 2.5371 6.6925 2.06051 5.91596C3.35514 4.62014 4.62251 3.35396 5.92426 2.05339C6.70673 2.53355 7.52832 3.03978 8.35347 3.54246C8.88698 3.8673 9.94331 3.44524 10.0927 2.84416C10.3262 1.90638 10.5491 0.965048 10.7839 0C12.5883 0 14.3785 0 16.2197 0C16.4366 0.906955 16.6548 1.8234 16.8777 2.73865C17.0555 3.46777 18.0763 3.89694 18.7082 3.50926C19.5144 3.01489 20.3182 2.52051 21.0829 2.05102C22.3763 3.34447 23.6318 4.59998 24.943 5.9124C24.4783 6.67235 23.9756 7.49038 23.4753 8.31079C23.1114 8.90713 23.5405 9.93976 24.2258 10.1081C25.1434 10.3334 26.0646 10.5503 27 10.7756C27 12.5954 27 14.3892 27 16.2197C26.1298 16.426 25.2667 16.6287 24.4048 16.8338C23.4658 17.0579 23.0651 18.0122 23.5654 18.8267C24.029 19.5819 24.4914 20.3383 24.9727 21.122C24.1487 22.004 23.3473 22.8612 22.4901 23.7776C21.5393 21.1741 19.8297 19.4243 17.3163 18.4592C20.5565 15.5332 19.8558 11.4668 17.659 9.41099C15.2973 7.19992 11.5995 7.26157 9.31378 9.56393C7.15368 11.7406 6.71858 15.6885 9.79749 18.4331Z"
                    fill="#FFBB38"
                  />
                  <path
                    d="M21.0695 30.3147C16.0415 30.3147 11.0847 30.3147 6.03891 30.3147C6.03891 29.9768 6.03416 29.6496 6.04009 29.3224C6.06262 28.0396 5.97963 26.7426 6.13612 25.4752C6.53566 22.2576 9.12611 19.9244 12.3722 19.8213C13.5886 19.7821 14.8417 19.7762 16.0249 20.0169C18.8643 20.5954 20.8916 23.0258 21.0552 25.9364C21.1359 27.3709 21.0695 28.8138 21.0695 30.3147Z"
                    fill="#FFBB38"
                  />
                  <path
                    d="M13.5375 17.9235C11.2244 17.9093 9.35005 16.0112 9.38325 13.7195C9.41763 11.4124 11.3169 9.55701 13.6157 9.58428C15.8849 9.61036 17.7486 11.5013 17.7403 13.7693C17.7332 16.0752 15.8481 17.9378 13.5375 17.9235Z"
                    fill="#FFBB38"
                  />
                </svg>
              </span>
            </div>
            <p className="custom-text-size text-white group-hover:text-qblacktext mt-5">
              Canceled
            </p>
            <span className="custom-font-size text-white group-hover:text-qblacktext font-bold leading-none mt-1 block">
              {myOrder ? myOrder.numOfCanceled : "-"}
            </span>
          </div>
        </div> */}
          <div className="dashboard-main-content">
            <div className="acct-info-msg "> Accounting Information</div>
            <div className="accounting-info-container">
              <div className="accounting-info-item">
                <div className="accounting-info-label">Company Name:</div>
                <div className="accounting-info-value">
                  {myProfile && myProfile.companyName
                    ? myProfile.companyName
                    : "-"}
                </div>
              </div>

              <div>
                <div className="multi-info-row">
                  <div className="multi-info-label accounting-info-label">
                    <div style={{ marginRight: "8px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.99967 0.333252C3.31767 0.333252 0.333008 3.31792 0.333008 6.99992C0.333008 10.6819 3.31767 13.6666 6.99967 13.6666C10.6817 13.6666 13.6663 10.6819 13.6663 6.99992C13.6663 3.31792 10.6817 0.333252 6.99967 0.333252ZM4.66634 5.33325C4.66634 5.02683 4.72669 4.72342 4.84396 4.44032C4.96122 4.15723 5.13309 3.90001 5.34976 3.68334C5.56643 3.46667 5.82365 3.29479 6.10675 3.17753C6.38984 3.06027 6.69326 2.99992 6.99967 2.99992C7.30609 2.99992 7.60951 3.06027 7.8926 3.17753C8.17569 3.29479 8.43292 3.46667 8.64959 3.68334C8.86626 3.90001 9.03813 4.15723 9.15539 4.44032C9.27265 4.72342 9.33301 5.02683 9.33301 5.33325C9.33301 5.95209 9.08717 6.54558 8.64959 6.98317C8.212 7.42075 7.61851 7.66658 6.99967 7.66658C6.38084 7.66658 5.78734 7.42075 5.34976 6.98317C4.91217 6.54558 4.66634 5.95209 4.66634 5.33325ZM11.1717 10.3226C10.6726 10.9503 10.0382 11.4572 9.31579 11.8054C8.59337 12.1536 7.80162 12.334 6.99967 12.3333C6.19773 12.334 5.40598 12.1536 4.68356 11.8054C3.96114 11.4572 3.32672 10.9503 2.82767 10.3226C3.90834 9.54725 5.38301 8.99992 6.99967 8.99992C8.61634 8.99992 10.091 9.54725 11.1717 10.3226Z"
                          fill="#363636"
                        />
                      </svg>
                    </div>
                    Username:
                  </div>
                  <div className="multi-info-label accounting-info-label">
                    <div style={{ marginRight: "8px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.5"
                        height="10"
                        viewBox="0 0 13 10"
                        fill="#363636"
                      >
                        <path
                          d="M12.75 1.25C12.75 0.5625 12.1875 0 11.5 0H1.5C0.8125 0 0.25 0.5625 0.25 1.25V8.75C0.25 9.4375 0.8125 10 1.5 10H11.5C12.1875 10 12.75 9.4375 12.75 8.75V1.25ZM11.5 1.25L6.5 4.375L1.5 1.25H11.5ZM11.5 8.75H1.5V2.5L6.5 5.625L11.5 2.5V8.75Z"
                          fill="#363636"
                        />
                      </svg>
                    </div>
                    Email:
                  </div>
                  <div className="multi-info-label accounting-info-label">
                    <div style={{ marginRight: "8px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M2 0.333252H6C6.53043 0.333252 7.03914 0.543966 7.41421 0.919038C7.78929 1.29411 8 1.80282 8 2.33325V11.6666C8 12.197 7.78929 12.7057 7.41421 13.0808C7.03914 13.4559 6.53043 13.6666 6 13.6666H2C1.46957 13.6666 0.960859 13.4559 0.585786 13.0808C0.210714 12.7057 0 12.197 0 11.6666L0 2.33325C0 1.80282 0.210714 1.29411 0.585786 0.919038C0.960859 0.543966 1.46957 0.333252 2 0.333252ZM2 1.66659C1.82319 1.66659 1.65362 1.73682 1.5286 1.86185C1.40357 1.98687 1.33333 2.15644 1.33333 2.33325V11.6666C1.33333 11.8434 1.40357 12.013 1.5286 12.138C1.65362 12.263 1.82319 12.3333 2 12.3333H6C6.17681 12.3333 6.34638 12.263 6.4714 12.138C6.59643 12.013 6.66667 11.8434 6.66667 11.6666V2.33325C6.66667 2.15644 6.59643 1.98687 6.4714 1.86185C6.34638 1.73682 6.17681 1.66659 6 1.66659H2ZM4 11.6666C3.82319 11.6666 3.65362 11.5963 3.5286 11.4713C3.40357 11.3463 3.33333 11.1767 3.33333 10.9999C3.33333 10.8231 3.40357 10.6535 3.5286 10.5285C3.65362 10.4035 3.82319 10.3333 4 10.3333C4.17681 10.3333 4.34638 10.4035 4.4714 10.5285C4.59643 10.6535 4.66667 10.8231 4.66667 10.9999C4.66667 11.1767 4.59643 11.3463 4.4714 11.4713C4.34638 11.5963 4.17681 11.6666 4 11.6666Z"
                          fill="#363636"
                        />
                      </svg>
                    </div>
                    Phone:
                  </div>
                </div>
                <div className="multi-info-row">
                  <div className="multi-info-value accounting-info-value">
                    {myProfile && myProfile.loginId ? myProfile.loginId : "-"}
                    {myProfile && myProfile.isAdmin
                      ? " (Admin)"
                      : " (Sub-user)"}
                  </div>
                  <div className="multi-info-value accounting-info-value">
                    {myProfile && myProfile.contactEmail
                      ? myProfile.contactEmail
                      : "-"}
                  </div>
                  <div className="multi-info-value accounting-info-value">
                    {myProfile && myProfile.contactPhone
                      ? myProfile.contactPhone
                      : "-"}
                  </div>
                </div>
              </div>

              <div>
                <div className="multi-info-label accounting-info-label">
                  <div style={{ marginRight: "8px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M7.5 7.6875C7.0856 7.6875 6.68817 7.52288 6.39515 7.22985C6.10212 6.93683 5.9375 6.5394 5.9375 6.125C5.9375 5.7106 6.10212 5.31317 6.39515 5.02015C6.68817 4.72712 7.0856 4.5625 7.5 4.5625C7.9144 4.5625 8.31183 4.72712 8.60485 5.02015C8.89788 5.31317 9.0625 5.7106 9.0625 6.125C9.0625 6.33019 9.02208 6.53337 8.94356 6.72294C8.86504 6.91251 8.74995 7.08476 8.60485 7.22985C8.45976 7.37495 8.28751 7.49004 8.09794 7.56856C7.90837 7.64708 7.70519 7.6875 7.5 7.6875ZM7.5 1.75C6.33968 1.75 5.22688 2.21094 4.40641 3.03141C3.58594 3.85188 3.125 4.96468 3.125 6.125C3.125 9.40625 7.5 14.25 7.5 14.25C7.5 14.25 11.875 9.40625 11.875 6.125C11.875 4.96468 11.4141 3.85188 10.5936 3.03141C9.77312 2.21094 8.66032 1.75 7.5 1.75Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  Shipping Address1:
                </div>
                <div className="accounting-info-value">
                  {myProfile && myProfile.shipToAddress1
                    ? myProfile.shipToAddress1
                    : "-"}
                  <br />
                  {myProfile && myProfile.shipToCity
                    ? myProfile.shipToCity
                    : "-"}
                  ,{" "}
                  {myProfile && myProfile.shipToState
                    ? myProfile.shipToState
                    : "-"}
                  ,{" "}
                  {myProfile && myProfile.shipToCountry
                    ? myProfile.shipToCountry
                    : "-"}{" "}
                  ,{" "}
                  {myProfile && myProfile.shipToZipCode
                    ? myProfile.shipToZipCode
                    : "-"}
                </div>
              </div>
              <div>
                <div className="multi-info-label accounting-info-label">
                  <div style={{ marginRight: "8px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M7.5 7.6875C7.0856 7.6875 6.68817 7.52288 6.39515 7.22985C6.10212 6.93683 5.9375 6.5394 5.9375 6.125C5.9375 5.7106 6.10212 5.31317 6.39515 5.02015C6.68817 4.72712 7.0856 4.5625 7.5 4.5625C7.9144 4.5625 8.31183 4.72712 8.60485 5.02015C8.89788 5.31317 9.0625 5.7106 9.0625 6.125C9.0625 6.33019 9.02208 6.53337 8.94356 6.72294C8.86504 6.91251 8.74995 7.08476 8.60485 7.22985C8.45976 7.37495 8.28751 7.49004 8.09794 7.56856C7.90837 7.64708 7.70519 7.6875 7.5 7.6875ZM7.5 1.75C6.33968 1.75 5.22688 2.21094 4.40641 3.03141C3.58594 3.85188 3.125 4.96468 3.125 6.125C3.125 9.40625 7.5 14.25 7.5 14.25C7.5 14.25 11.875 9.40625 11.875 6.125C11.875 4.96468 11.4141 3.85188 10.5936 3.03141C9.77312 2.21094 8.66032 1.75 7.5 1.75Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  Shipping Address2:
                </div>
                <div className="accounting-info-value">
                  {myProfile && myProfile.shipToAddress2
                    ? myProfile.shipToAddress2
                    : "-"}
                </div>
              </div>
            </div>
          </div>

          {/*
        <div className="dashboard-info ">
          <div className="">
            <p className="account-info-title font-semibold mb-5">
              Account Information
            </p>
            <div className="account-info-content">
              <div className="flex mb-5">
                <div className="text-qgraytwo w-[150px]">
                  <p>Company Name </p>
                </div>
                <div className="text-qblack">
                  {myProfile && myProfile.companyName
                    ? myProfile.companyName
                    : "-"}
                </div>
              </div>

              <div className="flex mb-5">
                <div className="text-qgraytwo w-[100px]">Username:</div>
                <div className="text-qblack">
                  {myProfile && myProfile.loginId ? myProfile.loginId : "-"}
                  <span className="text-red-500">
                    {myProfile && myProfile.isAdmin
                      ? "  (Admin)"
                      : "  (Sub-user)"}
                  </span>
                </div>
              </div>

              {myProfile && !myProfile.isAdmin && (
                <div className="flex mb-5">
                  <div className="text-qgraytwo w-[150px]">Admin Username:</div>
                  <div className="text-qblack">
                    {myProfile && myProfile.adminUsername
                      ? myProfile.adminUsername
                      : "-"}
                  </div>
                </div>
              )}

              <div className="flex mb-5">
                <div className="text-qgraytwo w-[70px]">Email:</div>
                <div className="text-qblack">
                  {myProfile && myProfile.contactEmail
                    ? myProfile.contactEmail
                    : "-"}
                </div>
              </div>

              <div className="flex mb-5">
                <div className="text-qgraytwo w-[70px]">Phone:</div>
                <div className="text-qblack">
                  {myProfile && myProfile.contactPhone
                    ? myProfile.contactPhone
                    : "-"}
                </div>
              </div>

              <div className="flex mb-5">
                <div className="text-qgraytwo w-[100px]">
                  Shipping Address1 :
                </div>
                <div className="text-qblack">
                  {myProfile && myProfile.shipToCity
                    ? myProfile.shipToCity
                    : "-"}
                  ,{" "}
                  {myProfile && myProfile.shipToState
                    ? myProfile.shipToState
                    : "-"}
                  ,{" "}
                  {myProfile && myProfile.shipToCountry
                    ? myProfile.shipToCountry
                    : "-"}
                </div>
              </div>

              <div className="flex mb-5">
                <div className="text-qgraytwo w-[100px]"> </div>
                <div className="text-qblack">
                  {myProfile && myProfile.shipToAddress1
                    ? myProfile.shipToAddress1
                    : "-"}
                </div>
              </div>

              <div className="flex mb-5">
                <div className="text-qgraytwo w-[100px]">
                  Shipping Address2:
                </div>
                <div className="text-qblack">
                  {myProfile && myProfile.shipToAddress2
                    ? myProfile.shipToAddress2
                    : "-"}
                </div>
              </div>

              <div className="flex mb-5">
                <div className="text-qgraytwo w-[70px]">Zipcode:</div>
                <div className="text-qblack">
                  {myProfile && myProfile.shipToZipCode
                    ? myProfile.shipToZipCode
                    : "-"}
                </div>
              </div>
            </div>
          </div>
                  </div> */}
        </div>
      </div>
    </>
  );
}
