import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import ProductsTable from "../Checkout/ProductsTableOrigin";
import OrderInfo from "../OrderConfirm/OrderInfo";
import { getOrderConfirmation } from "../../../actions/checkoutAction";

export default function Index({ cart = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { CustomerPO } = useParams();

  // validate user
  const { search } = useLocation(); // search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // order information
  const orderConfirmation = useSelector((state) => state.getOrderConfirmation);
  const { loading, error, orderInfo } = orderConfirmation;
  const poNumber = orderInfo?.data.result.ponumber;
  const dropship = orderInfo?.data.result.isDropShip;
  const orderStatus = orderInfo?.data.result.orderStatus;
  const createdDate = orderInfo?.data.result.createdDate;

  const carrier = orderInfo?.data.result.carrier;
  const carrierService = orderInfo?.data.result.carrierService;
  const requestedShipDate = orderInfo?.data.result.requestedShipDate;
  const signatureService = orderInfo?.data.result.signatureService;
  const note = orderInfo?.data.result.note;

  const orderId = orderInfo?.data.result.orderId;
  const email = orderInfo?.data.result.email;
  const totalPrice = orderInfo?.data.result.totalPrice;
  const discountedTotalPrice = orderInfo?.data.result.discountedTotalPrice;
  const shipToAddress = orderInfo?.data.result.shipToAddress;
  const billToAddress = orderInfo?.data.result.billToAddress;
  const itemList = orderInfo?.data.result.itemList;
  const loginId = userInfo?.result.user.loginId;

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logged in ");
      //dispatch(getShippingInfo(userId));
      dispatch(getOrderConfirmation(CustomerPO));
      console.log("orderConfirmation : ", orderConfirmation);
    }
  }, [dispatch, navigate, redirect, userInfo]);

  return (
    <Layout childrenClasses={`${cart ? "pt-0 pb-0" : ""} relative`}>
      <div className="cart-page-wrapper w-full pb-60px white-background">
        <div className="w-full mb-[100px]">
          {/* Page history */}
          <PageTitle
            title="Order Confirmation"
            breadcrumb={[{ name: "home", path: "/" }]}
          />
        </div>
        {/* product list */}
        <div className="w-full mt-[23px]">
          <div className="container-x mx-auto">
            {/* <ProductsTable cartItems={items?.data.result} /> */}
            <OrderInfo
              isLoading={loading}
              loginId={loginId}
              poNumber={poNumber}
              orderStatus={orderStatus}
              createdDate={createdDate}
              orderId={orderId}
              dropship={dropship}
              carrier={carrier}
              carrierService={carrierService}
              requestedShipDate={requestedShipDate}
              signatureService={signatureService}
              note={note}
              email={email}
              totalPrice={totalPrice}
              discountedTotalPrice={discountedTotalPrice}
              shipToAddress={shipToAddress}
              billToAddress={billToAddress}
              itemList={itemList}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
