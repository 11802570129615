import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  exportStatementPdf,
  getStatementLine,
} from "../../../actions/statementAction";
import invoiceLogo from "../invoiceLogo.png";
import styles from "./Statement.module.css";

export default function ViewStatementTab(props) {
  //erpInvoiceId is transffered in props as a parameter from parent component (index)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const statementId = props.statementId;

  // Retrieve userInfo from Redux state
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // if fail for user validation, redirect to login page
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const statementInfo = useSelector((state) => state.getStatementLine);
  const { loading, error, statement } = statementInfo;

  console.log("statement from ViewStatementTab", statement);

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      // Remove IRG token
      localStorage.removeItem("IRGAccesstoken");
      localStorage.removeItem("IRGtokenExpiry");

      navigate(redirect);
    } else {
      console.log("You are already logged in ");

      // Fetch statement line details
      console.log("statementId", statementId);
      dispatch(getStatementLine(statementId));
    }
  }, [dispatch, navigate, redirect, userInfo]);

  // change date form
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handleButtonClick = () => {
    navigate("/myaccount#statement");
  };

  const handleClickDownloadStatement = (statementid) => {
    dispatch(exportStatementPdf(statementid));
  };

  return (
    <>
      <div className="support-tab w-full">
        {/* Exporting pdf file */}
        <div className=" w-full flex items-center space-x-4">
          <button
            type="button"
            className="text-lg font-semibold"
            onClick={handleButtonClick}
          >
            {"< BACK"}
          </button>

          <div
            style={{
              color: "#fff",
              backgroundColor: "#04add1",
              padding: "8px 20px",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              textAlign: "center",
              cursor: "pointer",
              width: "200px",
              marginLeft: "auto",
              marginRight: "0",
              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#008080")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#04add1")}
            onClick={() => handleClickDownloadStatement(statementId)}
          >
            Download statement
          </div>
        </div>

        <div className=" border p-4 mt-5 mb-5">
          {/* Top Section */}
          <table width="100%" border="0" cellPadding="2">
            <tr>
              {/* Maevn Address */}
              <td
                width="35%"
                height="150"
                valign="top"
                style={{
                  fontSize: "12px",
                  paddingTop: "20px",
                  paddingLeft: "20px",
                }}
              >
                <strong>Asti Manufacturing Corp., Inc.</strong> <br />
                13950 Senlac Drive Suite 300
                <br />
                Farmers Branch, TX 75234
                <br />
                Tel (800) 993-1841
                <br />
                Fax (972) 241-1184
                <br />
                E-mail: orders@maevnuniforms.com
                <br />
              </td>
              {/* Maevn logo */}
              <td width="30%" align="center" valign="middle">
                <img
                  src={invoiceLogo}
                  width="100"
                  height="75"
                  alt="Invoice Logo"
                />
                <h3 style={{ marginTop: "20px", fontSize: "20px" }}>
                  <strong> STATEMENT </strong>
                </h3>
              </td>
              <td width="35%"></td>
            </tr>
            <tr>
              {/* Bill To Address */}
              <td
                valign="top"
                style={{
                  fontSize: "12px",
                  paddingTop: "20px",
                  paddingLeft: "20px",
                  paddingBottom: "20px",
                }}
              >
                <strong>Bill To: </strong>
                <br />
                {statement?.billToName}
                <br />
                {statement?.billToAddress}
                <br />
                {statement?.billToCity}, {statement?.billToState},
                {statement?.billToZip}
                <br />
                {statement?.billToCountry}
              </td>
              <td
                colSpan="2"
                align="center"
                valign="bottom"
                style={{ paddingBottom: "5px" }}
              >
                {/* Customer Accounting Info */}
                <table
                  width="100%"
                  cellPadding="5"
                  cellSpacing="4"
                  border="1"
                  className={styles.table_border}
                >
                  <tbody>
                    <tr>
                      <td
                        width="25%"
                        align="center"
                        style={{ fontSize: "12px" }}
                      >
                        <strong> Due Date</strong>
                      </td>
                      <td
                        width="25%"
                        align="center"
                        style={{ fontSize: "12px" }}
                      >
                        <strong>Terms</strong>
                      </td>
                      <td
                        width="25%"
                        align="center"
                        style={{ fontSize: "12px" }}
                      >
                        <strong>Account#</strong>
                      </td>
                      <td
                        width="25%"
                        align="center"
                        style={{ fontSize: "12px" }}
                      >
                        <strong>Amount Due</strong>
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="center"
                        style={{ fontSize: "12px", height: "30px" }}
                      >
                        {statement?.statementDate
                          ? formatDate(statement.statementDate)
                          : ""}
                      </td>
                      <td align="center" style={{ fontSize: "12px" }}>
                        {statement?.paymentTerms}
                      </td>
                      <td align="center" style={{ fontSize: "12px" }}>
                        {statement?.accountNum}
                      </td>
                      <td align="center" style={{ fontSize: "12px" }}>
                        {statement?.amountDue}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
          {/* Statement Lines */}
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="1"
            className={styles.table_border}
          >
            <thead>
              <tr height="20">
                <td width="10%" align="center" style={{ fontSize: "12px" }}>
                  <strong>Date</strong>
                </td>
                <td width="54%" align="center" style={{ fontSize: "12px" }}>
                  <strong>Description</strong>
                </td>
                <td width="12%" align="center" style={{ fontSize: "12px" }}>
                  <strong>
                    Invoice
                    <br />
                    Amount
                  </strong>
                </td>
                <td width="12%" align="center" style={{ fontSize: "12px" }}>
                  <strong>
                    Payment
                    <br />
                    Received
                  </strong>
                </td>
                <td width="12%" align="center" style={{ fontSize: "12px" }}>
                  <strong>
                    Balance
                    <br />
                    Amount
                  </strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {statement?.statementLines &&
                statement?.statementLines.map((line, index) => (
                  <tr key={index} height="20">
                    <td align="center" style={{ fontSize: "12px" }}>
                      {line.icpDate
                        ? formatDate(line.icpDate)
                        : line.nullIcpDate}
                    </td>
                    <td align="center" style={{ fontSize: "12px" }}>
                      {line.description}
                    </td>
                    <td
                      align="right"
                      style={{ fontSize: "12px", paddingRight: "5px" }}
                    >
                      {line.invoiceAmount.toFixed(2)}
                    </td>
                    <td
                      align="right"
                      style={{ fontSize: "12px", paddingRight: "5px" }}
                    >
                      {line.paymentReceived.toFixed(2)}
                    </td>
                    <td
                      align="right"
                      style={{ fontSize: "12px", paddingRight: "5px" }}
                    >
                      {line.balanceAmount.toFixed(2)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <br />

          {/* Amount Due by period */}
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="1"
            className={styles.table_border}
          >
            <thead>
              <tr height="30">
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  <strong>Current</strong>
                </td>
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  <strong>1-30 Days</strong>
                </td>
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  <strong>31-60 Days</strong>
                </td>
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  <strong>61-90 Days</strong>
                </td>
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  <strong>Over 90 Days</strong>
                </td>
                <td width="26%" align="center" style={{ fontSize: "12px" }}>
                  <strong>
                    Total
                    <br />
                    Amount Due
                  </strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr height="30">
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  {statement?.currentDue}
                </td>
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  {statement?.pastDue130}
                </td>
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  {statement?.pastDue3160}
                </td>
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  {statement?.pastDue6190}
                </td>
                <td width="14%" align="center" style={{ fontSize: "12px" }}>
                  {statement?.pastDueOver90}
                </td>
                <td width="26%" align="center" style={{ fontSize: "12px" }}>
                  {statement?.totalAmountDue}
                </td>
              </tr>
            </tbody>
          </table>
          <br />

          {/* Total Over Due */}
          <table style={{ width: "100%", border: "none", borderSpacing: "0" }}>
            <tbody>
              <tr style={{ height: "30px" }}>
                <td style={{ width: "80%" }}></td>
                <td style={{ width: "20%" }}>
                  <table
                    style={{ width: "100%", borderCollapse: "collapse" }}
                    className={styles.table_border}
                  >
                    <thead>
                      <tr style={{ height: "30px" }}>
                        <td
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "12px",
                          }}
                        >
                          <strong>
                            Total
                            <br />
                            Over Due
                          </strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ height: "30px" }}>
                        <td
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "12px",
                          }}
                        >
                          {statement?.totalOverDue}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ textAlign: "right", width: "100%", fontSize: "14px" }}>
            Due on{" "}
            {statement?.statementDate
              ? formatDate(statement?.statementDate)
              : ""}
          </div>
        </div>
      </div>
      {/* move to statement table page button */}
      <div className="flex justify-end">
        <div className="w-[100px] h-[40px] mt-4 mb-4 float-right">
          <button
            type="button"
            className="yellow-btn"
            onClick={handleButtonClick}
          >
            <div className="w-full text-sm font-semibold"> {"< BACK"} </div>
          </button>
        </div>
      </div>
    </>
  );
}
