import React from "react";
import IRGlogo from "../../../../images//IRG_logo_Teal1.png";

const IRGAuthPopup = ({
  isOpen,
  closePopup,
  verifyPasscode,
  failedAttempts,
  showError,
  setPasscode,
  passcodeValue,
  togglePasscodeVisibility,
  passcodeVisibility,
}) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div
        className={`popup-container ${
          showError ? "popup-container-error" : ""
        } ${failedAttempts >= 3 ? "popup-container-extended" : ""}`}
      >
        {/* Close Button */}
        <div className="close-button" onClick={closePopup}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            fill="none"
          >
            <path
              d="M16 1L1 16"
              stroke="#474747"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 1L16 16"
              stroke="#474747"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>

        {/* Title Section */}
        <div className="title-container">
          <img src={IRGlogo} alt="IRG Logo" className="company-logo" />
          <h2 className="title-text">Welcome IRG members!</h2>
        </div>

        {/* Subtitle */}
        <p className="subtitle-text">
          Please enter the password to access the page.
        </p>

        {/* Warning Message */}
        {failedAttempts >= 3 && (
          <div className="warning-message-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9 8.98002C9 8.71481 9.10536 8.46045 9.29289 8.27292C9.48043 8.08538 9.73478 7.98002 10 7.98002C10.2652 7.98002 10.5196 8.08538 10.7071 8.27292C10.8946 8.46045 11 8.71481 11 8.98002V14.98C11 15.2452 10.8946 15.4996 10.7071 15.6871C10.5196 15.8747 10.2652 15.98 10 15.98C9.73478 15.98 9.48043 15.8747 9.29289 15.6871C9.10536 15.4996 9 15.2452 9 14.98V8.98002ZM10 4.05103C9.73478 4.05103 9.48043 4.15638 9.29289 4.34392C9.10536 4.53145 9 4.78581 9 5.05103C9 5.31624 9.10536 5.5706 9.29289 5.75813C9.48043 5.94567 9.73478 6.05102 10 6.05102C10.2652 6.05102 10.5196 5.94567 10.7071 5.75813C10.8946 5.5706 11 5.31624 11 5.05103C11 4.78581 10.8946 4.53145 10.7071 4.34392C10.5196 4.15638 10.2652 4.05103 10 4.05103Z"
                fill="#FF4444"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10Z"
                fill="#FF4444"
              />
            </svg>
            <div className="warning-text">
              <span className="warning-message1">
                Forgot the password or need assistance?{" "}
              </span>
              <span className="warning-message2">
                Please email support@irgscrubs.com or text 402-440-1846.
              </span>{" "}
            </div>
          </div>
        )}

        {/* Password Input */}
        <div className="passcode-container">
          <label className="password-label">Password</label>
          <div
            className={`input-icon-container ${
              showError ? "input-icon-container-error" : ""
            }`}
          >
            <input
              placeholder="Enter your password"
              className="passcode-input"
              type={
                passcodeVisibility === "show-password" ? "text" : "password"
              }
              value={passcodeValue}
              onChange={(e) => setPasscode(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") verifyPasscode();
              }}
            />
            <div className="password-icon" onClick={togglePasscodeVisibility}>
              {passcodeVisibility === "show-password" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="21"
                  fill="none"
                >
                  {/* Hidden Icon */}
                </svg>
              )}
            </div>
          </div>
          {showError && (
            <p className="error-message">Please enter a valid password</p>
          )}
        </div>

        {/* Submit Button */}
        <button
          onClick={verifyPasscode}
          type="button"
          className="access-button"
        >
          Access
        </button>
        <div
          className="bottom-message"
        >
          <p className="join-text">Interested in joining IRG?</p>
          <p className="apply-text">
            Apply at:{" "}
            <a
              href="https://irgscrubs.com/new-retailer-application/"
              target="_blank"
              className="apply-link"
            >
              https://irgscrubs.com/new-retailer-application/
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default IRGAuthPopup;
